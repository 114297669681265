import api from './api';

const deleteItem = async (url: string, id: number | string) => {
  const response = await fetch(`${api}${url}/${id}`, {
    credentials: 'include',
    mode: 'cors',
    method: 'DELETE',
    headers: {
      'X-XSRF-TOKEN': sessionStorage.getItem('X-XSRF-TOKEN') || '',
      'content-type': 'application/json',
    },
  });
  return response;
};

export default deleteItem;
