import {
  Alert,
  Button,
  Checkbox,
  Col,
  DatePicker,
  Divider,
  Form,
  Input,
  InputNumber,
  Row,
  Select,
  Space,
  Table,
  TablePaginationConfig,
  Tabs,
  TabsProps,
} from 'antd';
import Title from 'antd/es/typography/Title';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate, useParams } from 'react-router-dom';

import { ActivationsType, ErrorReportType, LicenseType, ValidationType } from '../core/types';
import { useEffect, useMemo, useState } from 'react';

import { addLicense, editLicense, getSingleLicense } from '../core/api/license';
import { getCompanies } from '../core/api/company';
import { getGroups } from '../core/api/group';
import { getModules } from '../core/api/module';
import { getTextTemplates } from '../core/api/texttemplates';
import { useDebounce } from '../hooks';
import moment from 'moment';
import { ColumnsType } from 'antd/es/table';
import { getErrorReports } from '../core/api/errorreport';
import ExpandableTitle from '../components/ExpandableTitle';
import { getActivations } from '../core/api/activations';
import { getDeactivations } from '../core/api/deactivations';
import { CheckOutlined, CloseOutlined, DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import { getValidations } from '../core/api/validation';
import dayjs from 'dayjs';
import useFetch from '../core/api/useFetch';
import TextEditor from '../components/TextEditor';
import styled from 'styled-components';
import { routes } from '../router/routes';

const LicenseEdit = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const { handleResponse, Error } = useFetch();
  const [companies, setCompanies] = useState<any[]>([]);
  const [groups, setGroups] = useState<any[]>([]);
  const [modules, setModules] = useState<any[]>([]);
  const [backendActive, setBackendActive] = useState<boolean>(false);
  const [hostingActive, setHostingActive] = useState<boolean>(false);
  const [kioskActive, setKioskActive] = useState<boolean>(false);
  const [appActive, setAppActive] = useState<boolean>(false);
  const [hotfolderActive, setHotfolderActive] = useState<boolean>(false);
  const [userdataActive, setUserdataActive] = useState<boolean>(false);
  const [productdataActive, setProductdataActive] = useState<boolean>(false);
  const [appBackendActive, setAppBackendActive] = useState<boolean>(false);
  const [kioskCounter, setKioskCounter] = useState<number[]>([1]);
  const [appCounter, setAppCounter] = useState<number[]>([1]);
  const [hotfolderCounter, setHotfolderCounter] = useState<number[]>([1]);
  const [userdataCounter, setUserdataCounter] = useState<number[]>([1]);
  const [productdataCounter, setProductdataCounter] = useState<number[]>([1]);
  const [hostingCounter, setHostingCounter] = useState<number[]>([1]);
  const [companySearchValue, setCompanySearchValue] = useState<string>('');
  const debouncedCompanySearchValue = useDebounce(companySearchValue);
  const [groupSearchValue, setGroupSearchValue] = useState<string>('');
  const debouncedGroupSearchValue = useDebounce(groupSearchValue);
  const [serial, setSerial] = useState<string>('');
  const [errorData, setErrorData] = useState<ErrorReportType[]>([]);
  const [validationData, setValidationData] = useState<ValidationType[]>([]);
  const [activationData, setActivationData] = useState<ActivationsType[]>([]);
  const [deactivationData, setDeactivationData] = useState<ActivationsType[]>([]);
  const [errorSort, setErrorSort] = useState('license.serial,asc');
  const [validationSort, setValidationSort] = useState('created,desc');
  const [activationSort, setActivationSort] = useState('created,desc');
  const [deactivationSort, setDeactivationSort] = useState('created,desc');
  const [errorSearch, setErrorSearch] = useState<string>('');
  const [validationSearch, setValidationSearch] = useState<string>('');
  const [activationSearch, setActivationSearch] = useState<string>('');
  const [deactivationSearch, setDeactivationSearch] = useState<string>('');
  const [chosenTicketLog, setChosenTicketLog] = useState<string>('');
  const debouncedErrorSearch = useDebounce(errorSearch);
  const debouncedValidationSearch = useDebounce(errorSearch);
  const debouncedActivationSearch = useDebounce(errorSearch);
  const debouncedDeactivationSearch = useDebounce(errorSearch);
  const [templateValues, setTemplateValues] = useState<any>({});
  const [validToUnlimited, setValidToUnlimited] = useState<boolean>(true);
  const [validTo, setValidTo] = useState<string>('');
  const [pagination, setPagination] = useState<Partial<TablePaginationConfig>>({
    current: 1,
    pageSize: 20,
    showTotal: (total, range) => `${range[0]}-${range[1]} von ${total} Einträgen`,
  });
  const [validationPagination, setValidationPagination] = useState<Partial<TablePaginationConfig>>({
    current: 1,
    pageSize: 20,
    showTotal: (total, range) => `${range[0]}-${range[1]} von ${total} Einträgen`,
  });
  const [activationPagination, setActivationPagination] = useState<Partial<TablePaginationConfig>>({
    current: 1,
    pageSize: 20,
    showTotal: (total, range) => `${range[0]}-${range[1]} von ${total} Einträgen`,
  });
  const [deactivationPagination, setDeactivationPagination] = useState<
    Partial<TablePaginationConfig>
  >({
    current: 1,
    pageSize: 20,
    showTotal: (total, range) => `${range[0]}-${range[1]} von ${total} Einträgen`,
  });

  useEffect(() => {
    const fetchData = async () => {
      const errorReports = await getErrorReports(
        {
          page: pagination.current! - 1,
          size: pagination.pageSize,
          sort: [errorSort],
        },
        debouncedErrorSearch,
        [{ key: 'license.serial', value: serial }],
      );
      setErrorData(errorReports.content);
      setPagination({
        ...pagination,
        pageSize: errorReports.size,
        total: errorReports.totalElements,
        showTotal: (total, range) => `${range[0]}-${range[1]} von ${total} Einträgen`,
      });
    };
    if (serial) {
      fetchData().catch(console.error);
    }
  }, [pagination.current, pagination.pageSize, errorSort, debouncedErrorSearch, serial]);

  useEffect(() => {
    const fetchData = async () => {
      const validations = await getValidations(
        {
          page: validationPagination.current! - 1,
          size: validationPagination.pageSize,
          sort: [validationSort],
        },
        debouncedValidationSearch,
        [{ key: 'license.serial', value: serial }],
      );
      setValidationData(validations.content);
      setValidationPagination({
        ...validationPagination,
        pageSize: validations.size,
        total: validations.totalElements,
        showTotal: (total, range) => `${range[0]}-${range[1]} von ${total} Einträgen`,
      });
    };
    if (serial) {
      fetchData().catch(console.error);
    }
  }, [
    validationPagination.current,
    validationPagination.pageSize,
    validationSort,
    debouncedValidationSearch,
    serial,
  ]);

  useEffect(() => {
    const fetchData = async () => {
      const activations = await getActivations(
        {
          page: activationPagination.current! - 1,
          size: activationPagination.pageSize,
          sort: [activationSort],
        },
        debouncedActivationSearch,
        [{ key: 'license.serial', value: serial }],
      );
      setActivationData(activations.content);

      setActivationPagination({
        ...activationPagination,
        pageSize: activations.size,
        total: activations.totalElements,
        showTotal: (total, range) => `${range[0]}-${range[1]} von ${total} Einträgen`,
      });
    };
    if (serial) {
      fetchData().catch(console.error);
    }
  }, [
    activationPagination.current,
    activationPagination.pageSize,
    activationSort,
    debouncedActivationSearch,
    serial,
  ]);

  useEffect(() => {
    const fetchData = async () => {
      const activations = await getDeactivations(
        {
          page: deactivationPagination.current! - 1,
          size: deactivationPagination.pageSize,
          sort: [deactivationSort],
        },
        debouncedDeactivationSearch,
        [{ key: 'license.serial', value: serial }],
      );
      setDeactivationData(activations.content);
      setDeactivationPagination({
        ...deactivationPagination,
        pageSize: activations.size,
        total: activations.totalElements,
        showTotal: (total, range) => `${range[0]}-${range[1]} von ${total} Einträgen`,
      });
    };
    if (serial) {
      fetchData().catch(console.error);
    }
  }, [
    deactivationPagination.current,
    deactivationPagination.pageSize,
    deactivationSort,
    debouncedDeactivationSearch,
    serial,
  ]);

  const errorTableColumns: ColumnsType<ErrorReportType> = [
    {
      title: t('created_at'),
      dataIndex: 'created',
      key: 'created',
      sorter: true,
      defaultSortOrder: 'descend',
      render: (text) => moment(text).format('DD.MM.YYYY'),
    },
  ];
  const validationTableColumns: ColumnsType<ValidationType> = [
    {
      title: t('created_at'),
      dataIndex: 'created',
      key: 'created',
      sorter: true,
      defaultSortOrder: 'descend',
      render: (text) => moment(text).format('DD.MM.YYYY'),
    },
    {
      title: t('machine'),
      dataIndex: 'machine',
      key: 'machine',
      sorter: true,
    },
    {
      title: t('platform'),
      dataIndex: 'operatingSystem',
      key: 'operatingSystem',
      sorter: true,
    },
  ];

  const activationsTableColumns: ColumnsType<ActivationsType> = [
    {
      title: t('date'),
      dataIndex: 'created',
      key: 'created',
      defaultSortOrder: 'descend',
      sorter: true,
      render: (text) => moment(text).format('DD.MM.YYYY HH:mm:ss'),
    },
    {
      title: t('status'),
      dataIndex: 'success',
      key: 'success',
      sorter: true,
      render: (_, data) => <>{data.success ? <CheckOutlined /> : <CloseOutlined />}</>,
    },
    {
      title: t('release'),
      dataIndex: ['release', 'number'],
      key: 'release.number',
      sorter: true,
      render: (number, data) =>
        data?.release?.id ? (
          <Link to={`/releases/manage/${data.release.id}`}>{number}</Link>
        ) : (
          number
        ),
    },
    {
      title: t('ip_adress'),
      dataIndex: 'remoteIp',
      key: 'remoteIp',
      sorter: true,
    },
    {
      title: t('platform'),
      dataIndex: 'operatingSystem',
      key: 'operatingSystem',
      sorter: true,
    },
  ];

  const deactivationsTableColumns: ColumnsType<ActivationsType> = [
    {
      title: t('date'),
      dataIndex: 'created',
      key: 'created',
      defaultSortOrder: 'descend',
      sorter: true,
      render: (text) => moment(text).format('DD.MM.YYYY HH:mm:ss'),
    },
    {
      title: t('status'),
      dataIndex: 'success',
      key: 'success',
      sorter: true,
      render: (_, data) => <>{data.success ? <CheckOutlined /> : <CloseOutlined />}</>,
    },
    {
      title: t('release'),
      dataIndex: ['release', 'number'],
      key: 'release.number',
      sorter: true,
      render: (number, data) =>
        data?.release?.id ? (
          <Link to={`/releases/manage/${data.release.id}`}>{number}</Link>
        ) : (
          number
        ),
    },
    {
      title: t('ip_adress'),
      dataIndex: 'remoteIp',
      key: 'remoteIp',
      sorter: true,
    },
    {
      title: t('platform'),
      dataIndex: 'operatingSystem',
      key: 'operatingSystem',
      sorter: true,
    },
  ];

  const handleErrorTableChange = (pagination: TablePaginationConfig, _: any, sorter: any) => {
    setPagination(pagination);
    setErrorSort(`${sorter.columnKey},${sorter.order === 'descend' ? 'desc' : 'asc'}`);
  };

  const handleValidationTableChange = (pagination: TablePaginationConfig, _: any, sorter: any) => {
    setValidationPagination(pagination);
    setValidationSort(`${sorter.columnKey},${sorter.order === 'descend' ? 'desc' : 'asc'}`);
  };

  const handleActivationTableChange = (pagination: TablePaginationConfig, _: any, sorter: any) => {
    setActivationPagination(pagination);
    setActivationSort(`${sorter.columnKey},${sorter.order === 'descend' ? 'desc' : 'asc'}`);
  };

  const handleDeactivationTableChange = (
    pagination: TablePaginationConfig,
    _: any,
    sorter: any,
  ) => {
    setDeactivationPagination(pagination);
    setDeactivationSort(`${sorter.columnKey},${sorter.order === 'descend' ? 'desc' : 'asc'}`);
  };

  const initialValues = {
    numberOfActivations: 0,
    maxNumberOfActivations: 10,
    backend_server_active: false,
    kiosk_active: false,
    app_active: false,
    app_backend_administration: 'https://apps.livebook.de/push/login.php',
    valid: true,
  };

  const onFinish = async () => {
    const values = form.getFieldsValue(true); //Needed to get ALL values, even the ones not rendered yet
    const body: LicenseType = {
      serial: values.serial,
      company: {
        id: values.company,
      },
      group: {
        id: values.group,
      },
      modules: values.modules ? values.modules.map((val: any) => ({ id: val })) : [],
      dealId: values.dealId,
      comment: values.comment,
      numberOfActivations: values.numberOfActivations,
      maxNumberOfActivations: values.maxNumberOfActivations,
      invalidMessage: values.invalidMessage,
      valid: values.valid,
      validTo: validToUnlimited ? null : validTo,
      data: {
        backend_server: {
          active: values.backend_server_active,
          title: values.backend_server_title,
          show_description: values.backend_server_show_description,
          addition: values.backend_server_addition,
          url: values.backend_server_url,
        },
        app_backend: {
          active: values.app_backend_active,
          title: values.app_backend_title,
          show_description: values.app_backend_show_description,
          addition: values.app_backend_addition,
          administration: values.app_backend_administration,
          username: values.app_backend_username,
          password: values.app_backend_password,
        },
        hosting: {
          active: values.hosting_active,
          showToCustomer: values.hosting_show_to_customer,
          data: [],
        },
        kiosk: {
          active: values.kiosk_active,
          data: [],
        },
        app: {
          active: values.app_active,
          data: [],
        },
        hotfolder: {
          active: values.hotfolder_active,
          data: [],
        },
        userdata: {
          active: values.userdata_active,
          data: [],
        },
        productdata: {
          active: values.productdata_active,
          data: [],
        },
      },
    };
    hostingCounter.map((el) => {
      body.data.hosting.data.push({
        title: values['hosting_title_' + el],
        show_description: values['hosting_show_description_' + el],
        addition: values['hosting_addition_' + el],
        target: values['hosting_target_' + el],
        ftp_username: values['hosting_ftp_username_' + el],
        password: values['hosting_password_' + el],
        directory: values['hosting_directory_' + el],
      });
    });
    kioskCounter.map((el) => {
      body.data.kiosk.data.push({
        title: values['kiosk_title_' + el],
        show_description: values['kiosk_show_description_' + el],
        addition: values['kiosk_addition_' + el],
        url: values['kiosk_url_' + el],
      });
    });
    appCounter.map((el) => {
      body.data.app.data.push({
        title: values['app_title_' + el],
        show_description: values['app_show_description_' + el],
        addition: values['app_addition_' + el],
        ios_app: values['app_ios_app_' + el],
        android_app: values['app_android_app_' + el],
      });
    });
    hotfolderCounter.map((el) => {
      body.data.hotfolder.data.push({
        title: values['hotfolder_title_' + el],
        show_description: values['hotfolder_show_description_' + el],
        addition: values['hotfolder_addition_' + el],
        target: values['hotfolder_target_' + el],
        ftp_username: values['hotfolder_ftp_username_' + el],
        password: values['hotfolder_password_' + el],
        directory: values['hotfolder_directory_' + el],
      });
    });
    userdataCounter.map((el) => {
      body.data.userdata.data.push({
        title: values['userdata_title_' + el],
        show_description: values['userdata_show_description_' + el],
        addition: values['userdata_addition_' + el],
        target: values['userdata_target_' + el],
        ftp_username: values['userdata_ftp_username_' + el],
        password: values['userdata_password_' + el],
        directory: values['userdata_directory_' + el],
      });
    });
    productdataCounter.map((el) => {
      body.data.productdata.data.push({
        title: values['productdata_title_' + el],
        show_description: values['productdata_show_description_' + el],
        addition: values['productdata_addition_' + el],
        target: values['productdata_target_' + el],
        ftp_username: values['productdata_ftp_username_' + el],
        password: values['productdata_password_' + el],
        directory: values['productdata_directory_' + el],
      });
    });

    if (id) {
      body.id = parseInt(id);
      await handleResponse(editLicense(id, body), { data: body });
    } else {
      const resp = await handleResponse(addLicense(body), { data: body });
      const json = await resp.json();
      if (json.id) {
        navigate(routes.licenses_manage + '/' + json.id, { replace: true });
      }
    }
  };

  useMemo(() => {
    const fetchTextTemplates = async () => {
      const template = await getTextTemplates();
      const templateFieldValues: any = {
        backend_server_description: template.backend_server.description,
        hosting_description_0: template.hosting.description,
        kiosk_description_0: template.kiosk.description,
        app_description_0: template.app.description,
        productdata_description_0: template.productdata.description,
        userdata_description_0: template.userdata.description,
        app_backend_description: template.app_backend.description,
      };

      setTemplateValues(template);

      if (id) {
        const fetchLicense = async () => {
          const license = await getSingleLicense(id);
          if (license.data) {
            license.data.hosting &&
              setHostingCounter(license.data.hosting.data.map((_: any, index: number) => index));
            setKioskCounter(license.data.kiosk.data.map((_: any, index: number) => index));
            setAppCounter(license.data.app.data.map((_: any, index: number) => index));
            setHotfolderCounter(license.data.hotfolder.data.map((_: any, index: number) => index));
            setProductdataCounter(
              license.data.productdata.data.map((_: any, index: number) => index),
            );
            setUserdataCounter(license.data.userdata.data.map((_: any, index: number) => index));
          }

          setSerial(license.serial);

          const fieldValues: any = {
            dealId: license.dealId,
            serial: license.serial,
            company: license.company.id,
            group: license.group.id,
            comment: license.comment,
            invalidMessage: license.invalidMessage,
            valid: license.valid,
            numberOfActivations: license.numberOfActivations,
            maxNumberOfActivations: license.maxNumberOfActivations,
            modules: license.modules ? license.modules.map((val) => val.id) : [],
            backend_server_title:
              license.data && license.data.backend_server ? license.data.backend_server.title : '',
            backend_server_active:
              license.data && license.data.backend_server
                ? license.data.backend_server.active
                : false,
            backend_server_show_description:
              license.data && license.data.backend_server
                ? license.data.backend_server.show_description
                : true,
            backend_server_addition:
              license.data && license.data.backend_server
                ? license.data.backend_server.addition
                : '',
            backend_server_url:
              license.data && license.data.backend_server ? license.data.backend_server.url : '',

            hosting_active:
              license.data && license.data.hosting ? license.data.hosting.active : false,
            hosting_show_to_customer:
              license.data && license.data.hosting ? license.data.hosting.showToCustomer : false,
            kiosk_active: license.data && license.data.kiosk ? license.data.kiosk.active : false,
            app_active: license.data && license.data.kiosk ? license.data.app.active : false,
            hotfolder_active:
              license.data && license.data.hotfolder ? license.data.hotfolder.active : false,
            userdata_active:
              license.data && license.data.userdata ? license.data.userdata.active : false,
            productdata_active:
              license.data && license.data.productdata ? license.data.productdata.active : false,
            app_backend_title:
              license.data && license.data.app_backend ? license.data.app_backend.title : '',
            app_backend_active:
              license.data && license.data.app_backend ? license.data.app_backend.active : false,
            app_backend_show_description:
              license.data && license.data.app_backend
                ? license.data.app_backend.show_description
                : true,
            app_backend_addition:
              license.data && license.data.app_backend ? license.data.app_backend.addition : '',
            app_backend_administration:
              license.data && license.data.app_backend
                ? license.data.app_backend.administration
                : '',
            app_backend_username:
              license.data && license.data.app_backend ? license.data.app_backend.username : '',
            app_backend_password:
              license.data && license.data.app_backend ? license.data.app_backend.password : '',
          };

          if (license.data) {
            if (license.data.hosting) {
              for (let i = 0; i < license.data.hosting.data.length; i++) {
                templateFieldValues['hosting_description_' + i] = template.hosting.description;

                fieldValues['hosting_title_' + i] =
                  license.data && license.data.hosting ? license.data.hosting.data[i].title : '';
                fieldValues['hosting_show_description_' + i] =
                  license.data && license.data.hosting
                    ? license.data.hosting.data[i].show_description
                    : true;
                fieldValues['hosting_addition_' + i] =
                  license.data && license.data.hosting ? license.data.hosting.data[i].addition : '';
                fieldValues['hosting_target_' + i] =
                  license.data && license.data.hosting ? license.data.hosting.data[i].target : '';
                fieldValues['hosting_ftp_username_' + i] =
                  license.data && license.data.hosting
                    ? license.data.hosting.data[i].ftp_username
                    : '';
                fieldValues['hosting_password_' + i] =
                  license.data && license.data.hosting ? license.data.hosting.data[i].password : '';
                fieldValues['hosting_directory_' + i] =
                  license.data && license.data.hosting
                    ? license.data.hosting.data[i].directory
                    : '';
              }
            }
            if (license.data.kiosk) {
              for (let i = 0; i < license.data.kiosk.data.length; i++) {
                templateFieldValues['kiosk_description_' + i] = template.kiosk.description;

                fieldValues['kiosk_title_' + i] =
                  license.data && license.data.kiosk ? license.data.kiosk.data[i].title : '';
                fieldValues['kiosk_show_description_' + i] =
                  license.data && license.data.kiosk
                    ? license.data.kiosk.data[i].show_description
                    : true;
                fieldValues['kiosk_addition_' + i] =
                  license.data && license.data.kiosk ? license.data.kiosk.data[i].addition : '';
                fieldValues['kiosk_url_' + i] =
                  license.data && license.data.kiosk ? license.data.kiosk.data[i].url : '';
              }
            }

            if (license.data.app) {
              for (let i = 0; i < license.data.app.data.length; i++) {
                templateFieldValues['app_description_' + i] = template.app.description;

                fieldValues['app_title_' + i] =
                  license.data && license.data.app ? license.data.app.data[i].title : '';
                fieldValues['app_show_description_' + i] =
                  license.data && license.data.app
                    ? license.data.app.data[i].show_description
                    : true;
                fieldValues['app_addition_' + i] =
                  license.data && license.data.app ? license.data.app.data[i].addition : '';
                fieldValues['app_ios_app_' + i] =
                  license.data && license.data.app ? license.data.app.data[i].ios_app : '';
                fieldValues['app_android_app_' + i] =
                  license.data && license.data.app ? license.data.app.data[i].android_app : '';
              }
            }

            if (license.data.hotfolder) {
              for (let i = 0; i < license.data.hotfolder.data.length; i++) {
                templateFieldValues['hotfolder_description_' + i] = template.hotfolder.description;

                fieldValues['hotfolder_title_' + i] =
                  license.data && license.data.hotfolder
                    ? license.data.hotfolder.data[i].title
                    : '';
                fieldValues['hotfolder_show_description_' + i] =
                  license.data && license.data.hotfolder
                    ? license.data.hotfolder.data[i].show_description
                    : true;
                fieldValues['hotfolder_addition_' + i] =
                  license.data && license.data.hotfolder
                    ? license.data.hotfolder.data[i].addition
                    : '';
                fieldValues['hotfolder_target_' + i] =
                  license.data && license.data.hotfolder
                    ? license.data.hotfolder.data[i].target
                    : '';
                fieldValues['hotfolder_ftp_username_' + i] =
                  license.data && license.data.hotfolder
                    ? license.data.hotfolder.data[i].ftp_username
                    : '';
                fieldValues['hotfolder_password_' + i] =
                  license.data && license.data.hotfolder
                    ? license.data.hotfolder.data[i].password
                    : '';
                fieldValues['hotfolder_directory_' + i] =
                  license.data && license.data.hotfolder
                    ? license.data.hotfolder.data[i].directory
                    : '';
              }
            }

            if (license.data.userdata) {
              for (let i = 0; i < license.data.userdata.data.length; i++) {
                templateFieldValues['userdata_description_' + i] = template.userdata.description;

                fieldValues['userdata_title_' + i] =
                  license.data && license.data.userdata ? license.data.userdata.data[i].title : '';
                fieldValues['userdata_show_description_' + i] =
                  license.data && license.data.userdata
                    ? license.data.userdata.data[i].show_description
                    : true;
                fieldValues['userdata_addition_' + i] =
                  license.data && license.data.userdata
                    ? license.data.userdata.data[i].addition
                    : '';
                fieldValues['userdata_target_' + i] =
                  license.data && license.data.userdata ? license.data.userdata.data[i].target : '';
                fieldValues['userdata_ftp_username_' + i] =
                  license.data && license.data.userdata
                    ? license.data.userdata.data[i].ftp_username
                    : '';
                fieldValues['userdata_password_' + i] =
                  license.data && license.data.userdata
                    ? license.data.userdata.data[i].password
                    : '';
                fieldValues['userdata_directory_' + i] =
                  license.data && license.data.userdata
                    ? license.data.userdata.data[i].directory
                    : '';
              }
            }

            if (license.data.productdata) {
              for (let i = 0; i < license.data.productdata.data.length; i++) {
                templateFieldValues['productdata_description_' + i] =
                  template.productdata.description;

                fieldValues['productdata_title_' + i] =
                  license.data && license.data.productdata
                    ? license.data.productdata.data[i].title
                    : '';
                fieldValues['productdata_show_description_' + i] =
                  license.data && license.data.productdata
                    ? license.data.productdata.data[i].show_description
                    : true;
                fieldValues['productdata_addition_' + i] =
                  license.data && license.data.productdata
                    ? license.data.productdata.data[i].addition
                    : '';
                fieldValues['productdata_target_' + i] =
                  license.data && license.data.productdata
                    ? license.data.productdata.data[i].target
                    : '';
                fieldValues['productdata_ftp_username_' + i] =
                  license.data && license.data.productdata
                    ? license.data.productdata.data[i].ftp_username
                    : '';
                fieldValues['productdata_password_' + i] =
                  license.data && license.data.productdata
                    ? license.data.productdata.data[i].password
                    : '';
                fieldValues['productdata_directory_' + i] =
                  license.data && license.data.productdata
                    ? license.data.productdata.data[i].directory
                    : '';
              }
            }
          }

          setValidToUnlimited(license.validTo ? false : true);
          setValidTo(license.validTo ? license.validTo : '');

          setBackendActive(
            license.data && license.data.backend_server
              ? license.data.backend_server.active
              : false,
          );
          setHostingActive(
            license.data && license.data.hosting ? license.data.hosting.active : false,
          );
          setKioskActive(license.data && license.data.kiosk ? license.data.kiosk.active : false);
          setAppActive(license.data && license.data.app ? license.data.app.active : false);
          setHotfolderActive(
            license.data && license.data.hotfolder ? license.data.hotfolder.active : false,
          );
          setUserdataActive(
            license.data && license.data.userdata ? license.data.userdata.active : false,
          );
          setProductdataActive(
            license.data && license.data.productdata ? license.data.productdata.active : false,
          );
          setAppBackendActive(
            license.data && license.data.app_backend ? license.data.app_backend.active : false,
          );
          form.setFieldsValue({ ...templateFieldValues, ...fieldValues });
        };
        fetchLicense().catch(console.error);
      } else {
        form.setFieldsValue(templateFieldValues);
      }
    };

    const fetchModules = async () => {
      const module = await getModules({ size: 100, sort: ['name,asc'] });
      const checkBoxData = module.content.map((comp) => {
        return { value: comp.id, label: comp.name };
      });
      setModules(checkBoxData);
    };

    fetchModules().catch(console.error);
    fetchTextTemplates().catch(console.error);
  }, []);

  useEffect(() => {
    const fetchCompanies = async () => {
      const company = await getCompanies(
        { size: 1000, sort: ['name,asc'] },
        debouncedCompanySearchValue,
      );
      const selectData = company.content.map((comp) => {
        return { value: comp.id, label: comp.name };
      });
      setCompanies(selectData);
    };

    fetchCompanies().catch(console.error);
  }, [debouncedCompanySearchValue]);

  useEffect(() => {
    const fetchGroups = async () => {
      const group = await getGroups({ size: 100, sort: ['name,asc'] }, debouncedGroupSearchValue);
      const selectData = group.content.map((comp) => {
        return { value: comp.id, label: comp.name };
      });
      setGroups(selectData);
    };

    fetchGroups().catch(console.error);
  }, [debouncedGroupSearchValue]);

  const onSetProductDataActive = (checked: boolean) => {
    setProductdataActive(checked);
    if (userdataActive) {
      productdataCounter.forEach((element) => {
        form.setFieldValue(
          'productdata_target_' + element,
          form.getFieldValue('userdata_target_' + userdataCounter[0]),
        );
        form.setFieldValue(
          'productdata_ftp_username_' + element,
          form.getFieldValue('userdata_ftp_username_' + userdataCounter[0]),
        );
        form.setFieldValue(
          'productdata_password_' + element,
          form.getFieldValue('userdata_password_' + userdataCounter[0]),
        );
      });
    }
  };

  const onSetUserDataActive = (checked: boolean) => {
    setUserdataActive(checked);
    if (checked) {
      hotfolderCounter.forEach((element) => {
        const currentValue = form.getFieldValue('hotfolder_directory_' + element);
        if (!currentValue.includes('/ftp/hotfolder')) {
          form.setFieldValue(
            'hotfolder_directory_' + element,
            currentValue.replace('/ftp', '/ftp/hotfolder'),
          );
        }
      });
    }
    if (productdataActive) {
      userdataCounter.forEach((element) => {
        form.setFieldValue(
          'userdata_target_' + element,
          form.getFieldValue('productdata_target_' + productdataCounter[0]),
        );
        form.setFieldValue(
          'userdata_ftp_username_' + element,
          form.getFieldValue('productdata_ftp_username_' + productdataCounter[0]),
        );
        form.setFieldValue(
          'userdata_password_' + element,
          form.getFieldValue('productdata_password_' + productdataCounter[0]),
        );
      });
    }
  };

  const handleHotfolderAdd = () => {
    const newLastElement = hotfolderCounter[hotfolderCounter.length - 1] + 1;
    setHotfolderCounter([...hotfolderCounter, newLastElement]);
    form.setFieldValue(
      'hotfolder_target_' + newLastElement,
      form.getFieldValue('hotfolder_target_' + hotfolderCounter[0]),
    );
    form.setFieldValue(
      'hotfolder_ftp_username_' + newLastElement,
      form.getFieldValue('hotfolder_ftp_username_' + hotfolderCounter[0]),
    );
    form.setFieldValue(
      'hotfolder_password_' + newLastElement,
      form.getFieldValue('hotfolder_password_' + hotfolderCounter[0]),
    );
  };
  const handleUserDataAdd = () => {
    const newLastElement = userdataCounter[userdataCounter.length - 1] + 1;
    setUserdataCounter([...userdataCounter, newLastElement]);
    form.setFieldValue(
      'userdata_target_' + newLastElement,
      form.getFieldValue('userdata_target_' + userdataCounter[0]),
    );
    form.setFieldValue(
      'userdata_ftp_username_' + newLastElement,
      form.getFieldValue('userdata_ftp_username_' + userdataCounter[0]),
    );
    form.setFieldValue(
      'userdata_password_' + newLastElement,
      form.getFieldValue('userdata_password_' + userdataCounter[0]),
    );
  };
  const handleProductDataAdd = () => {
    const newLastElement = productdataCounter[productdataCounter.length - 1] + 1;
    setProductdataCounter([...productdataCounter, newLastElement]);
    form.setFieldValue(
      'productdata_target_' + newLastElement,
      form.getFieldValue('productdata_target_' + productdataCounter[0]),
    );
    form.setFieldValue(
      'productdata_ftp_username_' + newLastElement,
      form.getFieldValue('productdata_ftp_username_' + productdataCounter[0]),
    );
    form.setFieldValue(
      'productdata_password_' + newLastElement,
      form.getFieldValue('productdata_password_' + productdataCounter[0]),
    );
  };
  const handleHostingAdd = () => {
    const newLastElement = hostingCounter[hostingCounter.length - 1] + 1;
    setHostingCounter([...hostingCounter, newLastElement]);
    form.setFieldValue(
      'hosting_target_' + newLastElement,
      form.getFieldValue('hosting_target_' + hostingCounter[0]),
    );
    form.setFieldValue(
      'hosting_ftp_username_' + newLastElement,
      form.getFieldValue('hosting_ftp_username_' + hostingCounter[0]),
    );
    form.setFieldValue(
      'hosting_password_' + newLastElement,
      form.getFieldValue('hosting_password_' + hostingCounter[0]),
    );
  };

  const renderGeneralTab = () => {
    return (
      <>
        <Space style={{ marginBottom: '24px' }}>
          <Alert message={t('save_warning')} type='warning' showIcon />
        </Space>

        <Form.Item
          label={t('customer')}
          name='company'
          rules={[{ required: true, message: t('required_customer_message') }]}
        >
          <Select
            showSearch
            placeholder={t('no_customer_chosen')}
            options={companies}
            filterOption={() => true}
            searchValue={companySearchValue}
            onSearch={(value) => setCompanySearchValue(value)}
          />
        </Form.Item>
        <Form.Item
          label={t('serialnumber')}
          name='serial'
          rules={[{ required: true, message: t('required_serial_message') }]}
        >
          <Input placeholder='ABCD-123-456-789-0500-PRE' size='large' />
        </Form.Item>
        <Form.Item label={t('pipedrive_deal_id')} name='dealId'>
          <Input size='large' />
        </Form.Item>
        <Form.Item
          label={t('group')}
          name='group'
          rules={[{ required: true, message: t('required_group_message') }]}
        >
          <Select
            showSearch
            searchValue={groupSearchValue}
            onSearch={(value) => setGroupSearchValue(value)}
            placeholder={t('no_group_chosen')}
            filterOption={() => true}
            options={groups}
          />
        </Form.Item>
        <Form.Item label={t('valid_to')}>
          <Space>
            <Checkbox
              checked={validToUnlimited}
              onChange={(e) => {
                setValidToUnlimited(e.target.checked);
                setValidTo('');
              }}
            >
              {t('unlimited')}
            </Checkbox>
            <DatePicker
              format={'DD.MM.YYYY'}
              value={validTo !== '' ? dayjs(validTo).set('h', 12) : null}
              onChange={(date) => {
                setValidTo(date ? dayjs(date).toISOString() : '');
                setValidToUnlimited(false);
              }}
            />
          </Space>
        </Form.Item>
        <Form.Item label={t('valid')} valuePropName='checked' name='valid'>
          <Checkbox />
        </Form.Item>
        <Form.Item label={t('error_message')} name='invalidMessage'>
          <Input placeholder={t('error_message') || ''} />
        </Form.Item>
        <Form.Item label={t('modules')} name='modules'>
          <Checkbox.Group options={modules} />
        </Form.Item>
        <Form.Item label={t('comment')} name='comment'>
          <TextEditor placeholder={t('comment') || ''} />
        </Form.Item>
      </>
    );
  };

  const renderActivationsTab = () => {
    return (
      <>
        <Space style={{ marginBottom: '24px' }}>
          <Alert message={t('save_warning')} type='warning' showIcon />
        </Space>
        <Form.Item label={t('activations')} name='numberOfActivations'>
          <InputNumber placeholder={t('name') || ''} />
        </Form.Item>
        <Form.Item label={t('max_activations')} name='maxNumberOfActivations'>
          <InputNumber placeholder={t('name') || ''} />
        </Form.Item>
        <ExpandableTitle title={t('validation')} level={3}>
          <TableHeader>
            <Input
              value={validationSearch}
              onChange={(val) => setValidationSearch(val.target.value)}
              placeholder={t('search')}
              style={{ width: '270px' }}
            />
          </TableHeader>
          <Table
            rowKey={'created'}
            columns={validationTableColumns}
            dataSource={validationData}
            pagination={{ ...validationPagination, hideOnSinglePage: true }}
            onChange={handleValidationTableChange}
            expandable={{
              expandedRowRender: (record) => <p style={{ margin: 0 }}>{record.statusText}</p>,
              rowExpandable: (record) => record.statusText !== '',
            }}
          />
        </ExpandableTitle>
        <ExpandableTitle title={t('activations')} level={3}>
          <TableHeader>
            <Input
              value={activationSearch}
              onChange={(val) => setActivationSearch(val.target.value)}
              placeholder={t('search')}
              style={{ width: '270px' }}
            />
          </TableHeader>
          <Table
            rowKey={'created'}
            columns={activationsTableColumns}
            dataSource={activationData}
            pagination={{ ...activationPagination, hideOnSinglePage: true }}
            onChange={handleActivationTableChange}
            expandable={{
              expandedRowRender: (record) => <p style={{ margin: 0 }}>{record.statusText}</p>,
              rowExpandable: (record) => record.statusText !== '',
            }}
          />
        </ExpandableTitle>
        <ExpandableTitle title={t('deactivations')} level={3}>
          <TableHeader>
            <Input
              value={deactivationSearch}
              onChange={(val) => setDeactivationSearch(val.target.value)}
              placeholder={t('search')}
              style={{ width: '270px' }}
            />
          </TableHeader>
          <Table
            rowKey={'created'}
            columns={deactivationsTableColumns}
            dataSource={deactivationData}
            pagination={{ ...deactivationPagination, hideOnSinglePage: true }}
            onChange={handleDeactivationTableChange}
            expandable={{
              expandedRowRender: (record) => <p style={{ margin: 0 }}>{record.statusText}</p>,
              rowExpandable: (record) => record.statusText !== '',
            }}
          />
        </ExpandableTitle>
      </>
    );
  };

  const renderErrorReportsTab = () => {
    return (
      <>
        <TableHeader>
          <Input
            value={errorSearch}
            onChange={(val) => setErrorSearch(val.target.value)}
            placeholder={t('search')}
            style={{ width: '270px' }}
          />
        </TableHeader>
        <Table
          rowKey={'created'}
          columns={errorTableColumns}
          dataSource={errorData}
          pagination={{ ...pagination, hideOnSinglePage: true }}
          onRow={(record) => {
            return {
              onClick: () => setChosenTicketLog(record.log),
            };
          }}
          onChange={handleErrorTableChange}
        />
        <Input.TextArea
          style={{ height: '400px', marginTop: '34px' }}
          readOnly
          value={chosenTicketLog}
        />
      </>
    );
  };

  const renderProductInfoTab = () => {
    return (
      <>
        <>
          <Space>
            <Alert message={t('save_warning')} type='warning' showIcon />
          </Space>
          <Title level={3}>{t('backend_server')}</Title>
          <Form.Item label={t('active')} valuePropName='checked' name='backend_server_active'>
            <Checkbox onChange={(val) => setBackendActive(val.target.checked)} />
          </Form.Item>
          {backendActive && (
            <>
              <Form.Item label={t('title_optional')} name='backend_server_title'>
                <Input disabled={!backendActive} placeholder={t('title_optional') || ''} />
              </Form.Item>
              <Form.Item label={t('description')} name='backend_server_description'>
                <TextEditor noModules readOnly={true} />
              </Form.Item>
              <Form.Item
                label={t('show_description')}
                valuePropName='checked'
                name='backend_server_show_description'
                initialValue={true}
              >
                <Checkbox disabled={!backendActive} />
              </Form.Item>
              <Form.Item label={t('addition')} name='backend_server_addition'>
                <TextEditor readOnly={!backendActive} placeholder={t('addition') || ''} />
              </Form.Item>
              <Form.Item label={t('backend_server')} name='backend_server_url'>
                <Input disabled={!backendActive} placeholder={t('backend_server') || ''} />
              </Form.Item>
            </>
          )}
        </>
        <>
          <Title level={3}>{t('hosting')}</Title>
          <Form.Item label={t('active')} valuePropName='checked' name='hosting_active'>
            <Checkbox onChange={(val) => setHostingActive(val.target.checked)} />
          </Form.Item>
          <Form.Item
            label={t('show_to_customer')}
            valuePropName='checked'
            name='hosting_show_to_customer'
          >
            <Checkbox />
          </Form.Item>
          {hostingActive && (
            <>
              {hostingCounter.map((index) => (
                <div key={'hosting' + index}>
                  <Form.Item label={t('title_optional')} name={'hosting_title_' + index}>
                    <Input disabled={!hostingActive} placeholder={t('title_optional') || ''} />
                  </Form.Item>
                  <Form.Item
                    label={t('description')}
                    name={'hosting_description_' + index}
                    initialValue={templateValues.hosting.description}
                  >
                    <TextEditor noModules readOnly={true} />
                  </Form.Item>
                  <Form.Item
                    label={t('show_description')}
                    valuePropName='checked'
                    name={'hosting_show_description_' + index}
                    initialValue={true}
                  >
                    <Checkbox disabled={!hostingActive} />
                  </Form.Item>
                  <Form.Item label={t('addition')} name={'hosting_addition_' + index}>
                    <TextEditor readOnly={!hostingActive} placeholder={t('addition') || ''} />
                  </Form.Item>
                  <Form.Item label={t('target')} name={'hosting_target_' + index}>
                    <Input disabled={!hostingActive} placeholder={t('target') || ''} />
                  </Form.Item>
                  <Form.Item label={t('ftp_username')} name={'hosting_ftp_username_' + index}>
                    <Input disabled={!hostingActive} placeholder={t('ftp_username') || ''} />
                  </Form.Item>
                  <Form.Item label={t('password')} name={'hosting_password_' + index}>
                    <Input disabled={!hostingActive} placeholder={t('password') || ''} />
                  </Form.Item>
                  <Form.Item
                    label={t('directory')}
                    initialValue={'/web/html5'}
                    name={'hosting_directory_' + index}
                  >
                    <Input disabled={!hostingActive} placeholder={t('directory') || ''} />
                  </Form.Item>
                  {hostingCounter.length > 1 && (
                    <Row>
                      <Col span={20}>
                        <Button
                          style={{ float: 'right' }}
                          icon={<DeleteOutlined />}
                          danger
                          onClick={() =>
                            setHostingCounter(hostingCounter.filter((el) => el !== index))
                          }
                        >
                          {t('remove_entry')}{' '}
                        </Button>
                      </Col>
                    </Row>
                  )}
                  <Divider />
                </div>
              ))}
              <Row>
                <Col span={20}>
                  <Button
                    style={{ float: 'right' }}
                    icon={<PlusOutlined />}
                    onClick={() => handleHostingAdd()}
                  >
                    {t('add_new_entry')}{' '}
                  </Button>
                </Col>
              </Row>
            </>
          )}
        </>
        <>
          <Title level={3}>{t('kiosk')}</Title>
          <Form.Item label={t('active')} valuePropName='checked' name='kiosk_active'>
            <Checkbox onChange={(val) => setKioskActive(val.target.checked)} />
          </Form.Item>
          {kioskActive && (
            <>
              {kioskCounter.map((index) => (
                <div key={'kiosk_' + index}>
                  <Form.Item label={t('title_optional')} name={'kiosk_title_' + index}>
                    <Input disabled={!kioskActive} placeholder={t('title_optional') || ''} />
                  </Form.Item>
                  <Form.Item
                    label={t('description')}
                    name={'kiosk_description_' + index}
                    initialValue={templateValues.kiosk.description}
                  >
                    <TextEditor noModules readOnly={true} />
                  </Form.Item>
                  <Form.Item
                    label={t('show_description')}
                    valuePropName='checked'
                    name={'kiosk_show_description_' + index}
                    initialValue={true}
                  >
                    <Checkbox disabled={!kioskActive} />
                  </Form.Item>
                  <Form.Item label={t('addition')} name={'kiosk_addition_' + index}>
                    <TextEditor readOnly={!kioskActive} placeholder={t('addition') || ''} />
                  </Form.Item>
                  <Form.Item label={t('kiosk_link')} name={'kiosk_url_' + index}>
                    <Input disabled={!kioskActive} placeholder={t('kiosk_link') || ''} />
                  </Form.Item>
                  {kioskCounter.length > 1 && (
                    <Row>
                      <Col span={20}>
                        <Button
                          style={{ float: 'right' }}
                          icon={<DeleteOutlined />}
                          danger
                          onClick={() => setKioskCounter(kioskCounter.filter((el) => el !== index))}
                        >
                          {t('remove_entry')}{' '}
                        </Button>
                      </Col>
                    </Row>
                  )}
                  <Divider />
                </div>
              ))}
              <Row>
                <Col span={20}>
                  <Button
                    style={{ float: 'right' }}
                    icon={<PlusOutlined />}
                    onClick={() =>
                      setKioskCounter([...kioskCounter, kioskCounter[kioskCounter.length - 1] + 1])
                    }
                  >
                    {t('add_new_entry')}{' '}
                  </Button>
                </Col>
              </Row>
            </>
          )}
        </>

        <>
          <Title level={3}>{t('app')}</Title>
          <Form.Item label={t('active')} valuePropName='checked' name='app_active'>
            <Checkbox onChange={(val) => setAppActive(val.target.checked)} />
          </Form.Item>
          {appActive && (
            <>
              {appCounter.map((index) => (
                <div key={'app' + index}>
                  <Form.Item label={t('title_optional')} name={'app_title_' + index}>
                    <Input disabled={!appActive} placeholder={t('title_optional') || ''} />
                  </Form.Item>
                  <Form.Item
                    label={t('description')}
                    name={'app_description_' + index}
                    initialValue={templateValues.app.description}
                  >
                    <TextEditor noModules readOnly={true} />
                  </Form.Item>
                  <Form.Item
                    label={t('show_description')}
                    valuePropName='checked'
                    name={'app_show_description_' + index}
                    initialValue={true}
                  >
                    <Checkbox disabled={!appActive} />
                  </Form.Item>
                  <Form.Item label={t('addition')} name={'app_addition_' + index}>
                    <TextEditor readOnly={!appActive} placeholder={t('addition') || ''} />
                  </Form.Item>
                  <Form.Item label={t('ios_app')} name={'app_ios_app_' + index}>
                    <Input disabled={!appActive} placeholder={t('ios_app') || ''} />
                  </Form.Item>
                  <Form.Item label={t('android_app')} name={'app_android_app_' + index}>
                    <Input disabled={!appActive} placeholder={t('android_app') || ''} />
                  </Form.Item>
                  {appCounter.length > 1 && (
                    <Row>
                      <Col span={20}>
                        <Button
                          style={{ float: 'right' }}
                          icon={<DeleteOutlined />}
                          danger
                          onClick={() => setAppCounter(appCounter.filter((el) => el !== index))}
                        >
                          {t('remove_entry')}{' '}
                        </Button>
                      </Col>
                    </Row>
                  )}
                  <Divider />
                </div>
              ))}

              <Row>
                <Col span={20}>
                  <Button
                    style={{ float: 'right' }}
                    icon={<PlusOutlined />}
                    onClick={() =>
                      setAppCounter([...appCounter, appCounter[appCounter.length - 1] + 1])
                    }
                  >
                    {t('add_new_entry')}{' '}
                  </Button>
                </Col>
              </Row>
            </>
          )}
        </>
      </>
    );
  };

  const renderBaseDataTab = () => {
    return (
      <>
        <>
          <Space>
            <Alert message={t('save_warning')} type='warning' showIcon />
          </Space>
          <Title level={3}>{t('hotfolder')}</Title>
          <Form.Item label={t('active')} valuePropName='checked' name='hotfolder_active'>
            <Checkbox onChange={(val) => setHotfolderActive(val.target.checked)} />
          </Form.Item>
          {hotfolderActive && (
            <>
              {hotfolderCounter.map((index) => (
                <div key={'hotfolder' + index}>
                  <Form.Item label={t('title_optional')} name={'hotfolder_title_' + index}>
                    <Input disabled={!hotfolderActive} placeholder={t('title_optional') || ''} />
                  </Form.Item>
                  <Form.Item
                    label={t('description')}
                    name={'hotfolder_description_' + index}
                    initialValue={templateValues.hotfolder.description}
                  >
                    <TextEditor noModules readOnly={true} />
                  </Form.Item>
                  <Form.Item
                    label={t('show_description')}
                    valuePropName='checked'
                    name={'hotfolder_show_description_' + index}
                    initialValue={true}
                  >
                    <Checkbox disabled={!hotfolderActive} />
                  </Form.Item>
                  <Form.Item label={t('addition')} name={'hotfolder_addition_' + index}>
                    <TextEditor readOnly={!hotfolderActive} placeholder={t('addition') || ''} />
                  </Form.Item>
                  <Form.Item label={t('target')} name={'hotfolder_target_' + index}>
                    <Input disabled={!hotfolderActive} placeholder={t('target') || ''} />
                  </Form.Item>
                  <Form.Item label={t('ftp_username')} name={'hotfolder_ftp_username_' + index}>
                    <Input disabled={!hotfolderActive} placeholder={t('ftp_username') || ''} />
                  </Form.Item>
                  <Form.Item label={t('password')} name={'hotfolder_password_' + index}>
                    <Input disabled={!hotfolderActive} placeholder={t('password') || ''} />
                  </Form.Item>
                  <Form.Item
                    label={t('directory')}
                    initialValue={'/ftp'}
                    name={'hotfolder_directory_' + index}
                  >
                    <Input disabled={!hotfolderActive} placeholder={t('directory') || ''} />
                  </Form.Item>
                  {hotfolderCounter.length > 1 && (
                    <Row>
                      <Col span={20}>
                        <Button
                          style={{ float: 'right' }}
                          icon={<DeleteOutlined />}
                          danger
                          onClick={() =>
                            setHotfolderCounter(hotfolderCounter.filter((el) => el !== index))
                          }
                        >
                          {t('remove_entry')}{' '}
                        </Button>
                      </Col>
                    </Row>
                  )}
                  <Divider />
                </div>
              ))}
              <Row>
                <Col span={20}>
                  <Button
                    style={{ float: 'right' }}
                    icon={<PlusOutlined />}
                    onClick={() => handleHotfolderAdd()}
                  >
                    {t('add_new_entry')}{' '}
                  </Button>
                </Col>
              </Row>
            </>
          )}
        </>
        <>
          <Title level={3}>{t('userdata')}</Title>
          <Form.Item label={t('active')} valuePropName='checked' name='userdata_active'>
            <Checkbox onChange={(val) => onSetUserDataActive(val.target.checked)} />
          </Form.Item>
          {userdataActive && (
            <>
              {userdataCounter.map((index) => (
                <div key={'userdata' + index}>
                  <Form.Item label={t('title_optional')} name={'userdata_title_' + index}>
                    <Input disabled={!userdataActive} placeholder={t('title_optional') || ''} />
                  </Form.Item>
                  <Form.Item
                    label={t('description')}
                    name={'userdata_description_' + index}
                    initialValue={templateValues.userdata.description}
                  >
                    <TextEditor noModules readOnly={true} />
                  </Form.Item>
                  <Form.Item
                    label={t('show_description')}
                    valuePropName='checked'
                    name={'userdata_show_description_' + index}
                    initialValue={true}
                  >
                    <Checkbox disabled={!userdataActive} />
                  </Form.Item>
                  <Form.Item label={t('addition')} name={'userdata_addition_' + index}>
                    <TextEditor readOnly={!userdataActive} placeholder={t('addition') || ''} />
                  </Form.Item>
                  <Form.Item label={t('target')} name={'userdata_target_' + index}>
                    <Input disabled={!userdataActive} placeholder={t('target') || ''} />
                  </Form.Item>
                  <Form.Item label={t('ftp_username')} name={'userdata_ftp_username_' + index}>
                    <Input disabled={!userdataActive} placeholder={t('ftp_username') || ''} />
                  </Form.Item>
                  <Form.Item label={t('password')} name={'userdata_password_' + index}>
                    <Input disabled={!userdataActive} placeholder={t('password') || ''} />
                  </Form.Item>
                  <Form.Item
                    label={t('directory')}
                    initialValue={'/ftp/nutzerdaten'}
                    name={'userdata_directory_' + index}
                  >
                    <Input disabled={!userdataActive} placeholder={t('directory') || ''} />
                  </Form.Item>
                  {userdataCounter.length > 1 && (
                    <Row>
                      <Col span={20}>
                        <Button
                          style={{ float: 'right' }}
                          icon={<DeleteOutlined />}
                          danger
                          onClick={() =>
                            setUserdataCounter(userdataCounter.filter((el) => el !== index))
                          }
                        >
                          {t('remove_entry')}{' '}
                        </Button>
                      </Col>
                    </Row>
                  )}
                  <Divider />
                </div>
              ))}
              <Row>
                <Col span={20}>
                  <Button
                    style={{ float: 'right' }}
                    icon={<PlusOutlined />}
                    onClick={() => handleUserDataAdd()}
                  >
                    {t('add_new_entry')}{' '}
                  </Button>
                </Col>
              </Row>
            </>
          )}
        </>
        <>
          <Title level={3}>{t('productdata')}</Title>
          <Form.Item label={t('active')} valuePropName='checked' name='productdata_active'>
            <Checkbox onChange={(val) => onSetProductDataActive(val.target.checked)} />
          </Form.Item>
          {productdataActive && (
            <>
              {productdataCounter.map((index) => (
                <div key={'productdata' + index}>
                  <Form.Item label={t('title_optional')} name={'productdata_title_' + index}>
                    <Input disabled={!productdataActive} placeholder={t('title_optional') || ''} />
                  </Form.Item>
                  <Form.Item
                    label={t('description')}
                    name={'productdata_description_' + index}
                    initialValue={templateValues.productdata.description}
                  >
                    <TextEditor noModules readOnly={true} />
                  </Form.Item>
                  <Form.Item
                    label={t('show_description')}
                    valuePropName='checked'
                    name={'productdata_show_description_' + index}
                    initialValue={true}
                  >
                    <Checkbox disabled={!productdataActive} />
                  </Form.Item>
                  <Form.Item label={t('addition')} name={'productdata_addition_' + index}>
                    <TextEditor readOnly={!productdataActive} placeholder={t('addition') || ''} />
                  </Form.Item>
                  <Form.Item label={t('target')} name={'productdata_target_' + index}>
                    <Input disabled={!productdataActive} placeholder={t('target') || ''} />
                  </Form.Item>
                  <Form.Item label={t('ftp_username')} name={'productdata_ftp_username_' + index}>
                    <Input disabled={!productdataActive} placeholder={t('ftp_username') || ''} />
                  </Form.Item>
                  <Form.Item label={t('password')} name={'productdata_password_' + index}>
                    <Input disabled={!productdataActive} placeholder={t('password') || ''} />
                  </Form.Item>
                  <Form.Item
                    label={t('directory')}
                    initialValue={'/ftp/produktdaten'}
                    name={'productdata_directory_' + index}
                  >
                    <Input disabled={!productdataActive} placeholder={t('directory') || ''} />
                  </Form.Item>
                  {productdataCounter.length > 1 && (
                    <Row>
                      <Col span={20}>
                        <Button
                          style={{ float: 'right' }}
                          icon={<DeleteOutlined />}
                          danger
                          onClick={() =>
                            setProductdataCounter(productdataCounter.filter((el) => el !== index))
                          }
                        >
                          {t('remove_entry')}{' '}
                        </Button>
                      </Col>
                    </Row>
                  )}
                  <Divider />
                </div>
              ))}
              <Row>
                <Col span={20}>
                  <Button
                    style={{ float: 'right' }}
                    icon={<PlusOutlined />}
                    onClick={() => handleProductDataAdd()}
                  >
                    {t('add_new_entry')}{' '}
                  </Button>
                </Col>
              </Row>
            </>
          )}
        </>
        <>
          <Title level={3}>{t('app_backend')}</Title>
          <Form.Item label={t('active')} valuePropName='checked' name='app_backend_active'>
            <Checkbox onChange={(val) => setAppBackendActive(val.target.checked)} />
          </Form.Item>
          {appBackendActive && (
            <>
              <Form.Item label={t('title_optional')} name='app_backend_title'>
                <Input disabled={!appBackendActive} placeholder={t('title_optional') || ''} />
              </Form.Item>
              <Form.Item label={t('description')} name='app_backend_description'>
                <TextEditor noModules readOnly={true} />
              </Form.Item>
              <Form.Item
                label={t('show_description')}
                valuePropName='checked'
                name='app_backend_show_description'
                initialValue={true}
              >
                <Checkbox disabled={!appBackendActive} />
              </Form.Item>
              <Form.Item label={t('addition')} name='app_backend_addition'>
                <TextEditor readOnly={!appBackendActive} placeholder={t('addition') || ''} />
              </Form.Item>
              <Form.Item
                label={t('app_administration')}
                initialValue={'https://apps.publishing.one/push/login.php'}
                name='app_backend_administration'
              >
                <Input disabled={!appBackendActive} placeholder={t('backend_server') || ''} />
              </Form.Item>
              <Form.Item label={t('username')} name='app_backend_username'>
                <Input disabled={!appBackendActive} placeholder={t('username') || ''} />
              </Form.Item>
              <Form.Item label={t('password')} name='app_backend_password'>
                <Input disabled={!appBackendActive} placeholder={t('password') || ''} />
              </Form.Item>
            </>
          )}
        </>
      </>
    );
  };

  const tabItems: TabsProps['items'] = [
    {
      key: '1',
      label: t('general'),
      children: renderGeneralTab(),
    },
    {
      key: '2',
      label: t('activations'),
      children: renderActivationsTab(),
    },
    {
      key: '3',
      label: t('error_reports'),
      children: renderErrorReportsTab(),
    },
    {
      key: '4',
      label: t('productinfo'),
      children: renderProductInfoTab(),
    },
    {
      key: '5',
      label: t('basedata'),
      children: renderBaseDataTab(),
    },
  ];

  return (
    <Wrapper>
      <Error />
      <Title level={2}>{id ? t('edit_license') : t('new_license')}</Title>
      <Form
        form={form}
        onFinish={onFinish}
        initialValues={initialValues}
        name='basic'
        labelCol={{ span: 4 }}
        labelWrap
        wrapperCol={{ span: 16 }}
      >
        <Tabs defaultActiveKey='1' items={tabItems} size='middle' />
        <br />
        <Form.Item>
          <Space>
            <Button type='primary' htmlType='submit'>
              {t('save')}
            </Button>
            <Button htmlType='button' onClick={() => navigate(-1)}>
              {t('cancel')}
            </Button>
          </Space>
        </Form.Item>
      </Form>
    </Wrapper>
  );
};

export default LicenseEdit;

const Wrapper = styled.div`
  width: 100%;
`;

const TableHeader = styled.div`
  display: flex;
  justify-content: end;
  margin-bottom: 34px;
`;
