import { Translation } from './translation';

const translation: Translation = {
  login: 'Anmelden',
  license_data: 'Lizenzdaten',
  name: 'Name',
  version: 'Version',
  platform: 'Plattform',
  download: 'Download',
  open: 'Öffnen',
  serial_number: 'Seriennummer',
  day_of_last_delivery: 'Tag der letzten Auslieferung',
  date_of_termination: 'Kündigungstermin',
  activations: 'Aktivierungen',
  max_activations: 'Max. Aktivierungen',
  current_software_version: 'Aktuelle Software-Version',
  older_software_versions: 'Ältere Software-Versionen',
  backend_server: 'Backend-Server',
  backend_server_description:
    'Nach der Installation der Software fragt diese Sie nach einem Backend-Server. Bitte fügen Sie die folgende Adresse ein und wählen Sie „verbinden“.',
  kiosk: 'Kiosk',
  kiosk_description:
    'Ihr Web-Kiosk zeigt alle mit der publishing.one Software erstellten HTML5-Ausgaben gebündelt in einer Oberfläche an inkl. Suchfunktion und Favoriten. Strukturiert wird der Kiosk dank Kategorien. Über den folgenden Link gelangen Sie zu Ihrem Web-Kiosk. Diesen Link können Sie verwenden, um den Web-Kiosk in Ihre Homepage zu integrieren.',
  app: 'App',
  app_description:
    'Ihre publishing.one App ist eine native Anwendung für iOS sowie für Android zur Darstellung der mit der publishing.one Software generierten Inhalte. Die App kann über die Stores von Apple und Google installiert werden. Die folgenden Links sind die Direkt-Links zu Ihrer App im jeweiligen Store. Diese Links können Sie zum Bewerben Ihrer App verwenden.',
  groups: 'Gruppen',
  description: 'Beschreibung',
  updated_at: 'Update am',
  new_group: 'Neue Gruppe',
  save: 'Speichern',
  cancel: 'Abbrechen',
  delivery_cycle_after: 'Aktualisierungsrhythmus nach',
  after_new_version: 'nach dem Anlegen einer neuen Version',
  edit_group: 'Gruppe bearbeiten',
  weeks: 'Wochen',
  released_at: 'Veröffentlicht am',
  release: 'Version',
  releases: 'Versionen',
  new_release: 'Neue Version',
  edit_release: 'Version bearbeiten',
  release_notes: 'Release Notes',
  companies: 'Kunden',
  new_company: 'Neuer Kunde',
  edit_company: 'Kunde bearbeiten',
  organisation_id: 'Organisations-ID',
  active: 'Aktiv',
  modules: 'Module',
  new_module: 'Neues Modul',
  edit_module: 'Modul bearbeiten',
  status: 'Status',
  date: 'Datum',
  ip_adress: 'IP-Adresse',
  deactivations: 'Deaktivierungen',
  licenses: 'Lizenzen',
  new_license: 'Neue Lizenz',
  edit_license: 'Lizenz bearbeiten',
  created_at: 'Erstellt am',
  serialnumber: 'Seriennummer',
  customer: 'Kunde',
  no_customer_chosen: 'Noch keinen Kunden ausgewählt',
  no_group_chosen: 'Noch keinen Gruppe ausgewählt',
  comment: 'Kommentar (intern)',
  general: 'Allgemein',
  license_name: 'Lizenzname',
  group: 'Gruppe',
  valid: 'Gültig',
  valid_to: 'Gültig bis',
  unlimited: 'Unlimitiert',
  help: 'Hilfe',
  user_handbook: 'Benutzerhandbuch',
  installation_help: 'Installationshilfe',
  contact_support: 'Support kontaktieren',
  profile: 'Profil',
  logout: 'Logout',
  texttemplates: 'Textvorlagen',
  app_backend: 'App-Backend',
  productdata: 'Produktdaten',
  userdata: 'Benutzerdaten',
  hotfolder: 'Hotfolder',
  title_optional: 'Titel (optional)',
  productinfo: 'Produktinformationen',
  username: 'Benutzername',
  password: 'Passwort',
  addition: 'Abweichung/Zusatz',
  show_description: 'Beschreibung anzeigen',
  ios_app: 'iOS-App',
  android_app: 'Android-App',
  kiosk_link: 'Kiosk',
  add_new_entry: 'Eintrag hinzufügen',
  remove_entry: 'Eintrag löschen',
  basedata: 'Stammdaten',
  target: 'Ziel',
  ftp_username: 'FTP-Nutzername',
  directory: 'Verzeichnis',
  app_administration: 'App-Administration',
  edit_termination: 'Kündigung bearbeiten',
  delete: 'Löschen',
  delete_title: '{{item}} löschen?',
  delete_descripion: 'Möchten Sie diese {{item}} wirklich löschen?',
  search: 'Suche',
  license: 'Lizenz',
  module: 'Modul',
  windows: 'Windows',
  macos: 'macOS',
  linux: 'Linux',
  release_type_software: 'Software',
  release_type_app: 'App',
  release_type_kiosk: 'Kiosk',
  download_type_full: 'publishing.one-Software',
  download_type_client: 'publishing.one-Software Client',
  download_type_server: 'publishing.one-Software',
  download_type_manual: 'Benutzerhandbuch',
  download_type_manual_server: 'Benutzerhandbuch Server-Installation',
  add: 'Hinzufügen',
  user_id: 'Benutzer ID',
  public: 'Öffentlich',
  internal_notes: 'Notizen (intern)',
  delivery_date: 'Auslieferungsdatum',
  error_while_saving: 'Fehler beim Speichern (Status: {{status}})',
  handbook_version: 'Handbuchversion',
  use_richtext_editor: 'WYSIWYG Editor',
  do_open: 'Öffnen',
  installation_kind: 'Installationsart',
  operating_system: 'Betriebssystem',
  full_installation: 'Vollversion',
  error_reports: 'Fehlerberichte',
  validation: 'Validierung',
  machine: 'Rechner',
  error_message: 'Fehlermeldung',
  welcome: 'Herzlich willkommen!',
  welcome_message:
    'Im publishing.one Kundenbereich finden Sie alle Informationen rund um Ihre lizensierte publishing.one Software. Melden Sie sich mit Ihren Zugangsdaten an:',
  imprint: 'Impressum',
  data_protection: 'Datenschutz',
  product: 'Produkt',
  close: 'Schließen',
  no_access: 'Kein Zugriff',
  user_license_expired_description:
    'Ihr Benutzerkonto ist mit keiner aktiven Lizenz verknüpft, da Ihr Lizenzvertrag gekündigt wurde. Wenn Sie die publishing.one Software weiterhin in Ihrem Unternehmen nutzen möchten, wenden Sie sich bitte zum Abschluss eines neuen Lizenzvertrages an unseren Kundenservice unter info@publishing.one.',
  contact_support_link: 'Support kontaktieren',
  contact_support_description:
    'Achtung: Ihr Benutzer ist keinem Kundenprofil zugeordnet. Bitte teilen Sie uns mit, für welches Unternehmen Sie arbeiten. Wenden Sie sich dazu bitte an unser Support Team.',
  pipedrive_deal_id: 'Pipedrive Deal ID',
  required_customer_message: 'Bitte wählen Sie einen Kunden aus der Liste aus.',
  required_serial_message: 'Bitte geben Sie eine Seriennummer ein.',
  required_group_message: 'Bitte wählen Sie eine Gruppe aus der Liste aus.',
  save_warning: 'Denk daran, deine Änderungen zu speichern',
  save_success: 'Änderungen gespeichert',
  hosting: 'Ausgaben-Hosting',
  show_to_customer: 'Beim Kunden anzeigen',
  placeholder_value_examples: 'Beispiel für Platzhalterwerte',
  hosting_placeholder_value_explanation:
    'Für den Server $ZIEL mit dem Verzeichnis $VERZEICHNIS ist der Nutzername $BENUTZERNAME und das Passwort $PASSWORT',
  selected_error_report: 'Ausgewählter Fehlerbericht',
};

export default translation;
