import { PageType, ErrorReportType } from '../types';
import get, { Pagination } from '../crud/get';

const API_URL = 'api/errorReport';

export const getErrorReports = async (
  pagination?: Pagination,
  search?: string,
  staticSearch?: { key: string; value: string }[],
) => {
  const searchParameters: any = [];
  if (search) {
    searchParameters.push({ key: 'installerVersion', value: search });
    searchParameters.push({ key: 'created', value: search });
    searchParameters.push({ key: 'license.serial', value: search.toUpperCase() });
    searchParameters.push({ key: 'log', value: search.toUpperCase() });
    searchParameters.push({ key: 'message', value: search.toUpperCase() });
  }
  if (staticSearch) {
    staticSearch.forEach((element) => {
      const exists = searchParameters.findIndex((e: any) => e.key === element.key);
      if (exists > -1) {
        searchParameters[exists] = element;
      } else {
        searchParameters.push(element);
      }
    });
  }
  const reports = await get(API_URL, pagination, searchParameters);
  const data = (await reports.json()) as any;
  return data as PageType<ErrorReportType>;
};

export const getSingleErrorReport = async (id: number | string) => {
  const report = await get(API_URL, id);
  const data = (await report.json()) as any;
  return data as ErrorReportType;
};
