const API: string =
  // read from global variable, e.g. injected by nginx reverse proxy
  (window as any)?.env?.BACKEND_URL ??
  // read from local environment, e.g. used for development
  process.env.REACT_APP_API_URL ??
  (() => {
    throw new Error("Missing env parameter: 'REACT_APP_API_URL'");
  })();

const CURRENT_API = (() => {
  if (API.endsWith('/')) {
    return API;
  } else {
    return `${API}/`;
  }
})();

export default CURRENT_API;
