import styled from 'styled-components';
import { Navigate, Outlet, useLocation } from 'react-router-dom';

import Header from '../container/Header';
import Footer from '../container/Footer';
import UserNavigation from '../container/UserNavigation';
import { useAppDispatch, useAppSelector } from '../hooks';
import { routes } from './routes';
import { LoginState, changeView, login } from '../redux/reducers/userReducer';
import AdminNavigation from '../container/AdminNavigation';
import { useEffect } from 'react';

export const LoginLayout = () => {
  return (
    <Wrapper>
      <Header />
      <Container>
        <Outlet />
      </Container>
      <Footer />
    </Wrapper>
  );
};

export const AdminLayout = () => {
  const user = useAppSelector((state) => state.user);
  const location = useLocation();
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(login());
    dispatch(changeView('Admin'));
  }, [dispatch, location]);

  return (
    <Wrapper>
      {user.loggedIn !== LoginState.LoggedIn && <Navigate to={routes.login} />}
      {user.loggedIn === LoginState.LoggedIn &&
        !user.authorities.some((auth) => auth.authority === 'ROLE_ADMIN') && (
          <Navigate to={routes.home} />
        )}
      <Header />
      <Container>
        <AdminNavigation />
        <Outlet />
      </Container>

      <Footer />
    </Wrapper>
  );
};

export const UserLayout = () => {
  const user = useAppSelector((state) => state.user);
  const location = useLocation();
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(login());
    dispatch(changeView('User'));
  }, [dispatch, location]);

  return (
    <Wrapper>
      {user.loggedIn !== LoginState.LoggedIn && <Navigate to={routes.login} />}

      <Header />
      <Container>
        <UserNavigation />
        <Outlet />
      </Container>

      <Footer />
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh;
  align-items: center;
  overflow-y: scroll;
`;

const Container = styled.div`
  flex: 1;
  width: 100%;
  max-width: 1240px;
  margin-bottom: 24px;
`;
