import { ReleaseNoteType } from '../types';
import get from '../crud/get';

const API_URL = 'public/releasenotes';

export type ReleaseNotesType = {
  SOFTWARE: ReleaseNoteType[];
  APP: ReleaseNoteType[];
  KIOSK: ReleaseNoteType[];
};

export const getReleasenotes = async () => {
  const activations = await get(API_URL);
  return (await activations.json()) as ReleaseNotesType;
};
