import { useEffect } from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { useAppSelector } from '../hooks';
import { Button } from 'antd';
import { LoginState } from '../redux/reducers/userReducer';
import { Typography, Space } from 'antd';
import { routes } from '../router/routes';
import api from '../core/crud/api';
import { useTranslation } from 'react-i18next';

const { Title, Paragraph, Text } = Typography;

const Login = () => {
  const user = useAppSelector((state) => state.user);
  const { t } = useTranslation();
  const navigate = useNavigate();
  useEffect(() => {
    if (user.loggedIn === LoginState.LoggedIn) {
      if (user.authorities.some((auth) => auth.authority === 'ROLE_ADMIN')) {
        navigate(routes.admin, { replace: true });
      } else {
        navigate(routes.home, { replace: true });
      }
    }
  }, [user, navigate]);
  const redirectURL = window.location.href;

  return (
    <Wrapper>
      <Space direction='vertical' size='middle' align='center'>
        <TextWrapper>
          <Title>{t('welcome')}</Title>
          <Paragraph>
            <Text>{t('welcome_message')}</Text>
          </Paragraph>
        </TextWrapper>

        <Button type='primary' href={`${api}oauth2/authorization/keycloak?continue=${redirectURL}`}>
          {t('login')}
        </Button>
      </Space>
    </Wrapper>
  );
};

export default Login;

const Wrapper = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const TextWrapper = styled.div`
  text-align: center;
  max-width: 600px;
`;
