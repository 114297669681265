import { Menu } from 'antd';
import type { MenuProps } from 'antd';
import { useAppDispatch, useAppSelector } from '../hooks';
import { logout, LoginState } from '../redux/reducers/userReducer';
import API_URL from '../core/crud/api';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { routes } from '../router/routes';
interface Props {
  userName: string;
}

const DropdownNavigation = ({ userName }: Props) => {
  const { t } = useTranslation();
  const user = useAppSelector((state) => state.user);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const menuItems: MenuProps['items'] = [
    user.authorities.some((auth) => auth.authority === 'ROLE_ADMIN')
      ? {
          label: `Ansicht: ${user.admin.view}`,
          key: 'changeView',
        }
      : null,
    user.loggedIn === LoginState.LoggedIn
      ? {
          label: t('help'),
          key: 'help',
          children: [
            {
              label: t('user_handbook'),
              key: 'user_handbook',
            },
            {
              label: t('contact_support'),
              key: 'contact_support',
            },
          ],
        }
      : null,
    {
      label: userName,
      key: 'username',
      children: [
        {
          label: t('profile'),
          key: 'profile',
        },
        {
          label: t('logout'),
          key: 'logout',
        },
      ],
    },
  ];

  const onMenuClick: MenuProps['onClick'] = (e) => {
    switch (e.key) {
      case 'changeView':
        user.admin.view === 'User' ? navigate(routes.admin) : navigate(routes.home);
        break;
      case 'logout':
        dispatch(logout());
        break;
      case 'profile':
        window.open(`${API_URL}user/profile`, '_blank');
        break;
      case 'user_handbook':
        window.open('https://support.publishing.one/handbuch');
        break;
      case 'contact_support':
        location.href =
          'mailto:support@publishing.one?subject=' +
          encodeURIComponent('Support-Anfrage zu publishing.one');
        break;
      default:
        break;
    }
  };

  return (
    <Menu
      mode='horizontal'
      items={menuItems}
      onClick={onMenuClick}
      theme='dark'
      style={{ width: '70%', justifyContent: 'right' }}
    ></Menu>
  );
};

export default DropdownNavigation;
