import { Button, Result } from 'antd';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';

const UserLicenseNotValid = () => {
  const { t } = useTranslation();
  const { state } = useLocation();
  return (
    <Wrapper>
      <Result
        status='warning'
        title={t('no_access')}
        subTitle={state?.message ?? t('user_license_expired_description')}
        extra={
          <Button type='primary' href='mailto:support@publishing.one'>
            Support
          </Button>
        }
      />
    </Wrapper>
  );
};

export default UserLicenseNotValid;

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;
