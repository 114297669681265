import { PageType, ValidationType } from '../types';
import get, { Pagination } from '../crud/get';

const API_URL = 'api/validation';

export const getValidations = async (
  pagination?: Pagination,
  search?: string,
  staticSearch?: { key: string; value: string }[],
) => {
  const searchParameters: any = [];
  if (search) {
    searchParameters.push({ key: 'installerVersion', value: search });
    searchParameters.push({ key: 'created', value: search });
    searchParameters.push({ key: 'license.serial', value: search.toUpperCase() });
  }
  if (staticSearch) {
    staticSearch.forEach((element) => {
      const exists = searchParameters.findIndex((e: any) => e.key === element.key);
      if (exists > -1) {
        searchParameters[exists] = element;
      } else {
        searchParameters.push(element);
      }
    });
  }
  const validation = await get(API_URL, pagination, searchParameters);
  const data = (await validation.json()) as any;
  return data as PageType<ValidationType>;
};

export const getSingleValidation = async (id: number | string) => {
  const validation = await get(API_URL, id);
  const data = (await validation.json()) as any;
  return data as ValidationType;
};
