import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.bubble.css';
import styled from 'styled-components';
type Props = {
  value?: string;
};

const RichtextViewer: React.FC<Props> = ({ value }: Props) => {
  return (
    <>
      <Viewer value={value || ''} readOnly theme='bubble' />
    </>
  );
};

export default RichtextViewer;

const Viewer = styled(ReactQuill)`
  .ql-container {
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial,
      'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
      'Noto Color Emoji';
    font-size: 14px;
  }
  .ql-editor {
    padding-left: 0px;
  }
`;
