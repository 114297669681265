import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import { LoginLayout, AdminLayout, UserLayout } from './layouts';
import Home from '../pages/Home';
import Login from '../pages/Login';
import GroupEdit from '../pages/GroupEdit';

import { routes } from './routes';
import { useAppDispatch, useAppSelector } from '../hooks';
import { login, LoginState } from '../redux/reducers/userReducer';
import { Spin } from 'antd';
import { useEffect } from 'react';
import Productinfo from '../pages/Productinfo';
import Groups from '../pages/Groups';
import Releases from '../pages/Releases';
import ReleaseEdit from '../pages/ReleaseEdit';
import Companies from '../pages/Companies';
import CompanyEdit from '../pages/CompanyEdit';
import Modules from '../pages/Modules';
import ModuleEdit from '../pages/ModuleEdit';
import Activations from '../pages/Activations';
import Deactivations from '../pages/Deactivations';
import Licenses from '../pages/Licenses';
import LicenseEdit from '../pages/LicenseEdit';
import TextTemplates from '../pages/TextTemplates';
import BaseData from '../pages/BaseData';
import ReleaseNotes from '../pages/ReleaseNotes';
import ErrorReports from '../pages/ErrorReports';
import UserNotAssigned from '../pages/UserNotAssigned';
import UserLicenseNotValid from '../pages/UserLicenseNotValid';

const router = createBrowserRouter([
  {
    element: <AdminLayout />,
    children: [
      {
        path: routes.admin,
        element: <Home />,
      },
      {
        path: routes.licenses,
        element: <Licenses />,
      },
      {
        path: routes.licenses_manage,
        element: <LicenseEdit />,
      },
      {
        path: `${routes.licenses_manage}/:id`,
        element: <LicenseEdit />,
      },
      {
        path: routes.releases,
        element: <Releases />,
      },
      {
        path: routes.releases_manage,
        element: <ReleaseEdit />,
      },
      {
        path: `${routes.releases_manage}/:id`,
        element: <ReleaseEdit />,
      },
      {
        path: routes.groups,
        element: <Groups />,
      },
      {
        path: routes.groups_manage,
        element: <GroupEdit />,
      },
      {
        path: `${routes.groups_manage}/:id`,
        element: <GroupEdit />,
      },
      {
        path: routes.companies,
        element: <Companies />,
      },
      {
        path: routes.companies_manage,
        element: <CompanyEdit />,
      },
      {
        path: `${routes.companies_manage}/:id`,
        element: <CompanyEdit />,
      },
      {
        path: routes.errorreport,
        element: <ErrorReports />,
      },
      {
        path: routes.activations,
        element: <Activations />,
      },
      {
        path: routes.deactivations,
        element: <Deactivations />,
      },
      {
        path: routes.texttemplates,
        element: <TextTemplates />,
      },
      {
        path: routes.modules,
        element: <Modules />,
      },
      {
        path: routes.modules_manage,
        element: <ModuleEdit />,
      },
      {
        path: `${routes.modules_manage}/:id`,
        element: <ModuleEdit />,
      },
    ],
  },
  {
    element: <UserLayout />,
    children: [
      {
        index: true,
        element: <Home />,
      },
      {
        path: routes.productinfo,
        element: <Productinfo />,
      },
      {
        path: routes.data,
        element: <BaseData />,
      },
      {
        path: routes.releasenotes,
        element: <ReleaseNotes />,
      },
      {
        path: routes.user_not_assigned,
        element: <UserNotAssigned />,
      },
      {
        path: routes.user_license_invalid,
        element: <UserLicenseNotValid />,
      },
    ],
  },
  {
    element: <LoginLayout />,
    children: [
      {
        path: routes.login,
        element: <Login />,
      },
    ],
  },
]);

const AppRouter = () => {
  const user = useAppSelector((state) => state.user);
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(login());
  }, [dispatch]);
  return user.loggedIn === LoginState.Unknown ? <AppSpinner /> : <RouterProvider router={router} />;
};

function AppSpinner() {
  const style: React.CSSProperties = {
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  };
  return <Spin size='large' style={style} />;
}

export default AppRouter;
