import { PageType, ReleaseType } from '../types';
import put from '../crud/put';
import post from '../crud/post';
import get, { Pagination } from '../crud/get';
import deleteItem from '../crud/delete';

const API_URL = 'api/release';

export const addRelease = (body: ReleaseType) => {
  return post(API_URL, body);
};

export const editRelease = (id: number | string, body: ReleaseType) => {
  return put(API_URL, id, body);
};

export const getReleases = async (pagination?: Pagination, search?: string) => {
  const searchParameters: any = [];
  if (search) {
    searchParameters.push({ key: 'number', value: search });
  }
  const groups = await get(API_URL, pagination, searchParameters);
  const data = (await groups.json()) as any;
  return data as PageType<ReleaseType>;
};

export const getSingleRelease = async (id: number | string) => {
  const groups = await get(API_URL, id);
  const data = (await groups.json()) as any;
  return data as ReleaseType;
};

export const deleteRelease = async (id: number | string) => {
  await deleteItem(API_URL, id);
};
