import { Alert, Button, Checkbox, DatePicker, Form, Input, InputNumber, Space, Switch } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import Title from 'antd/es/typography/Title';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';

import { useEffect, useState } from 'react';
import { ReleaseType } from '../core/types';

import dayjs from 'dayjs';
import styled from 'styled-components';
import { getGroups } from '../core/api/group';
import { addRelease, editRelease, getSingleRelease } from '../core/api/release';
import { routes } from '../router/routes';
import TextEditor from '../components/TextEditor';
import useFetch from '../core/api/useFetch';

type FormType = {
  type: ReleaseType['type'];
  number: string;
  releaseNotes: string;
  releasedAt: any;
  visible: boolean;
  notes?: string;
  manualVersion?: number;
  deliveries: {
    id: number;
    name: string;
    releaseDate?: any;
    notes?: string;
  }[];
};

const ReleaseEdit = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const { handleResponse, Error } = useFetch();
  const [form] = Form.useForm<FormType>();
  // the release's original data (only available on editing)
  const [release, setRelease] = useState<ReleaseType | null>(null);
  // if release notes should be rendered by a wysiwyg editor or a plain text area
  const [releaseNotesWysiwygMode, setReleaseNotesWysiwygMode] = useState(!id);
  // the form's `type` value
  const type = Form.useWatch('type', form);
  // the form's `manualVersion` value
  const manualVersion = Form.useWatch('manualVersion', form);
  const navigate = useNavigate();

  const initialValues: FormType = {
    type: 'SOFTWARE',
    releasedAt: dayjs().set('hours', 12),
    number: '',
    releaseNotes: '',
    visible: false,
    deliveries: [],
  };

  const manualHref = release?.downloads?.find((d) => d.type === 'MANUAL');

  const hasManualHref = !!(manualHref && manualVersion);

  const onOpenManual = () => {
    const url = manualHref?.hrefTemplate?.replace(/\{VERSION\}/, String(manualVersion));
    if (url) {
      window.open(url, 'p1_manual');
    }
  };

  const onToggleReleaseNotesEditor = () => setReleaseNotesWysiwygMode(!releaseNotesWysiwygMode);

  const onFinish = async (values: FormType) => {
    const body: ReleaseType = {
      ...release,
      ...values,
      releasedAt: dayjs(values.releasedAt).set('hours', 12).toISOString(),
      deliveries: values.deliveries
        .filter((d) => d.releaseDate ?? d.notes) // don't save empty deliveries
        .map((d) => ({
          group: { id: d.id },
          releaseDate: d.releaseDate ? dayjs(d.releaseDate).set('hours', 12).toISOString() : null,
          notes: d.notes,
        })),
    };

    if (id) {
      body.id = parseInt(id);
      await handleResponse(editRelease(id, body), { data: body });
    } else {
      await handleResponse(addRelease(body), { data: body });
    }

    navigate(routes.releases, { replace: true });
  };

  useEffect(() => {
    // initialize form
    if (id) {
      const initializeForm = async () => {
        const [release, groups] = await Promise.all([
          getSingleRelease(id),
          getGroups({ size: 1e10 }),
        ]);
        const fieldValues: FormType = {
          ...release,
          releasedAt: dayjs(release.releasedAt).set('hours', 12),
          deliveries: groups.content.map((g) => {
            const delivery = release.deliveries?.find((d) => d.group.id === g.id);
            return {
              id: g.id!,
              name: g.name!,
              releaseDate: delivery?.releaseDate
                ? dayjs(delivery?.releaseDate).set('hours', 12)
                : null,
              notes: delivery?.notes,
            };
          }),
        };
        setRelease(release);
        form.setFieldsValue(fieldValues);
      };
      initializeForm().catch(console.error);
    } else {
      const initializeDeliveries = async () => {
        const groups = await getGroups({ size: 1e10 });
        form.setFieldValue(
          'deliveries',
          groups.content.map((g) => ({
            id: g.id!,
            name: g.name!,
            releaseDate: null,
            notes: null,
          })),
        );
      };
      initializeDeliveries().catch(console.error);
    }
  }, [id]);

  return (
    <Wrapper>
      <Error />
      <Title level={2}>{id ? t('edit_release') : t('new_release')}</Title>
      <Space style={{ marginBottom: '24px' }}>
        <Alert message={t('save_warning')} type='warning' showIcon />
      </Space>
      <Form<FormType>
        form={form}
        onFinish={onFinish}
        initialValues={initialValues}
        name='basic'
        labelCol={{ span: 4 }}
        labelWrap
        wrapperCol={{ span: 16 }}
      >
        <Form.Item hidden name='type' rules={[{ required: true }]}>
          {/* TODO: add type select control */}
        </Form.Item>
        <Form.Item
          label={t('release')}
          name='number'
          rules={[
            { required: true, message: 'Bitte geben Sie dem Release eine Nummer' },
            type === 'SOFTWARE' ? { pattern: /\d+\.\d+\.\d+\.0/ } : {},
          ]}
        >
          <Input placeholder={t('name') || ''} />
        </Form.Item>
        <Form.Item
          label={t('released_at')}
          name='releasedAt'
          rules={[{ required: true, message: 'Bitte geben Sie ein Datum ein' }]}
        >
          <DatePicker format={'DD.MM.YYYY'} />
        </Form.Item>
        <Form.Item label={t('public')} name='visible' valuePropName='checked'>
          <Checkbox />
        </Form.Item>
        <Form.Item label={t('internal_notes')} name='notes'>
          <Input.TextArea />
        </Form.Item>
        <Form.Item label={t('use_richtext_editor')}>
          <Switch checked={releaseNotesWysiwygMode} onChange={onToggleReleaseNotesEditor} />
        </Form.Item>
        <Form.Item label={t('release_notes')} name='releaseNotes'>
          {releaseNotesWysiwygMode ? <TextEditor /> : <Input.TextArea rows={12} />}
        </Form.Item>
        <Form.Item
          label={t('handbook_version')}
          name='manualVersion'
          rules={[{ required: type === 'SOFTWARE' }]}
          dependencies={['manualVersion']}
        >
          <InputNumber
            min={1}
            addonAfter={
              <Button type='text' disabled={!hasManualHref} onClick={onOpenManual}>
                <UploadOutlined />
              </Button>
            }
          />
        </Form.Item>
        <Form.Item label=' ' colon={false} style={{ marginBottom: 0, fontWeight: 600 }}>
          <div style={{ display: 'grid', gridTemplateColumns: '2fr 3fr', gap: 20 }}>
            <Title level={5} style={{ textAlign: 'start', marginTop: '0' }}>
              {t('delivery_date')}
            </Title>
            <Title level={5} style={{ textAlign: 'start', marginTop: '0' }}>
              {t('internal_notes')}
            </Title>
          </div>
        </Form.Item>
        <Form.List name='deliveries'>
          {(fields) =>
            fields.map(({ key, name }) => (
              <Form.Item key={key} label={form.getFieldValue(['deliveries', name, 'name'])}>
                <div
                  style={{
                    display: 'grid',
                    gridTemplateColumns: '2fr 3fr',
                    gap: 16,
                    marginBottom: '-24px',
                  }}
                >
                  <Form.Item name={[name, 'releaseDate']}>
                    <DatePicker format={'DD.MM.YYYY'} />
                  </Form.Item>
                  <Form.Item name={[name, 'notes']}>
                    <Input />
                  </Form.Item>
                </div>
              </Form.Item>
            ))
          }
        </Form.List>
        <Form.Item>
          <Space>
            <Button type='primary' htmlType='submit'>
              {t('save')}
            </Button>
            <Button htmlType='button' onClick={() => navigate(-1)}>
              {t('cancel')}
            </Button>
          </Space>
        </Form.Item>
      </Form>
    </Wrapper>
  );
};

export default ReleaseEdit;

const Wrapper = styled.div`
  width: 100%;
`;
