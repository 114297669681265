import { Button, Space, Typography, theme, Input } from 'antd';
import Table, { ColumnsType, TablePaginationConfig } from 'antd/es/table';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDebounce } from '../hooks';
import { useNavigate } from 'react-router-dom';
import { routes } from '../router/routes';
import styled from 'styled-components';
import { deleteGroup, getGroups } from '../core/api/group';
import { GroupType } from '../core/types';
import { DeleteTwoTone, EditOutlined } from '@ant-design/icons';
import DeleteModal from '../container/DeleteModal';

const { Title } = Typography;
const { useToken } = theme;

const Groups = () => {
  const { t } = useTranslation();
  const { token } = useToken();
  const [reloadTable, setReloadTable] = useState(true);
  const [groupData, setGroupData] = useState<GroupType[]>([]);
  const [tableSearch, setTableSearch] = useState<string>('');
  const debouncedTableSearch = useDebounce(tableSearch);
  const [idToDelete, setIDtoDelete] = useState<number>(0);
  const [pagination, setPagination] = useState<Partial<TablePaginationConfig>>({
    current: 1,
    pageSize: 20,
    showTotal: (total, range) => `${range[0]}-${range[1]} von ${total} Einträgen`,
  });
  const [sort, setSort] = useState('name,asc');
  const navigate = useNavigate();
  useEffect(() => {
    const fetchData = async () => {
      const groups = await getGroups(
        {
          page: pagination.current! - 1,
          size: pagination.pageSize,
          sort: [sort],
        },
        debouncedTableSearch,
      );
      setGroupData(groups.content);
      setPagination({
        ...pagination,
        pageSize: groups.size,
        total: groups.totalElements,
        showTotal: (total, range) => `${range[0]}-${range[1]} von ${total} Einträgen`,
      });
    };
    fetchData().catch(console.error);
  }, [reloadTable, pagination.current, pagination.pageSize, sort, debouncedTableSearch]);

  const handleDelete = async (id?: number) => {
    if (id) {
      await deleteGroup(id);
      setReloadTable(!reloadTable);
      setIDtoDelete(0);
    }
  };

  const groupsTableColumns: ColumnsType<GroupType> = [
    {
      title: t('name'),
      dataIndex: 'name',
      key: 'name',
      defaultSortOrder: 'ascend',
      sorter: true,
      width: '30%',
    },
    {
      title: t('description'),
      dataIndex: 'description',
      key: 'description',
      sorter: true,
    },

    {
      key: 'actions',
      width: '5%',
      render: (_, data) => (
        <Space size='middle' style={{ display: 'flex', justifyContent: 'end' }}>
          <EditOutlined
            onClick={() => navigate(routes.groups_manage + '/' + data.id)}
            style={{ fontSize: '18px' }}
          />
          <DeleteTwoTone
            twoToneColor={token.colorError}
            onClick={() => setIDtoDelete(data.id!)}
            style={{ fontSize: '18px' }}
          />
        </Space>
      ),
    },
  ];

  const handleTableChange = (pagination: TablePaginationConfig, _: any, sorter: any) => {
    setPagination(pagination);
    setSort(`${sorter.columnKey},${sorter.order === 'descend' ? 'desc' : 'asc'}`);
  };

  return (
    <Wrapper>
      <TitleWrapper>
        <Title level={2} style={{ fontWeight: 600 }}>
          {t('groups')}
        </Title>
        <TableHeader>
          <Space>
            <Input
              value={tableSearch}
              onChange={(val) => setTableSearch(val.target.value)}
              placeholder={t('search')}
              style={{ width: '270px' }}
            />
            <Button type='primary' onClick={() => navigate(routes.groups_manage)}>
              {t('new_group')}
            </Button>
          </Space>
        </TableHeader>
      </TitleWrapper>
      <Table
        rowKey={'id'}
        columns={groupsTableColumns}
        dataSource={groupData}
        pagination={{ ...pagination, hideOnSinglePage: true }}
        onChange={handleTableChange}
      />
      <DeleteModal
        type={t('group')}
        onOk={() => handleDelete(idToDelete)}
        isOpen={idToDelete > 0}
        onCancel={() => setIDtoDelete(0)}
      />
    </Wrapper>
  );
};

export default Groups;

const Wrapper = styled.div`
  width: 100%;
`;

const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 1rem;
  margin-bottom: 1rem;
`;

const TableHeader = styled.div`
  display: flex;
  justify-content: end;
  align-items: center;
`;
