import { Typography, Input, Form } from 'antd';
import Table, { ColumnsType, TablePaginationConfig } from 'antd/es/table';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDebounce } from '../hooks';
import styled from 'styled-components';
import { ErrorReportType } from '../core/types';
import { getErrorReports } from '../core/api/errorreport';
import moment from 'moment';

const { Title } = Typography;

const ErrorReports = () => {
  const { t } = useTranslation();
  const [errorData, setErrorData] = useState<ErrorReportType[]>([]);
  const [sort, setSort] = useState('license.serial,asc');
  const [tableSearch, setTableSearch] = useState<string>('');
  const [selectedErrorReport, setSelectedErrorReport] = useState<ErrorReportType | null>(null);
  const debouncedTableSearch = useDebounce(tableSearch);
  const [pagination, setPagination] = useState<Partial<TablePaginationConfig>>({
    current: 1,
    pageSize: 20,
    showTotal: (total, range) => `${range[0]}-${range[1]} von ${total} Einträgen`,
  });

  useEffect(() => {
    const fetchData = async () => {
      const errorReports = await getErrorReports(
        {
          page: pagination.current! - 1,
          size: pagination.pageSize,
          sort: [sort],
        },
        debouncedTableSearch,
      );
      setErrorData(errorReports.content);
      setPagination({
        ...pagination,
        pageSize: errorReports.size,
        total: errorReports.totalElements,
        showTotal: (total, range) => `${range[0]}-${range[1]} von ${total} Einträgen`,
      });
    };
    fetchData().catch(console.error);
  }, [pagination.current, pagination.pageSize, sort, debouncedTableSearch]);

  const errorTableColumns: ColumnsType<ErrorReportType> = [
    {
      title: t('serial_number'),
      dataIndex: 'license.serial',
      key: 'license.serial',
      defaultSortOrder: 'ascend',
      sorter: true,
    },
    {
      title: t('version'),
      dataIndex: 'installerVersion',
      key: 'installerVersion',
      sorter: true,
    },
    {
      title: t('created_at'),
      dataIndex: 'created',
      key: 'created',
      sorter: true,
      render: (text) => moment(text).format('DD.MM.YYYY'),
    },
  ];

  const handleTableChange = (pagination: TablePaginationConfig, _: any, sorter: any) => {
    setPagination(pagination);
    setSort(`${sorter.columnKey},${sorter.order === 'descend' ? 'desc' : 'asc'}`);
  };

  return (
    <Wrapper>
      <TitleWrapper>
        <Title level={2} style={{ fontWeight: 600 }}>
          {t('error_reports')}
        </Title>
        <TableHeader>
          <Input
            value={tableSearch}
            onChange={(val) => setTableSearch(val.target.value)}
            placeholder={t('search')}
            style={{ width: '270px' }}
          />
        </TableHeader>
      </TitleWrapper>

      <Table
        rowKey={'created'}
        columns={errorTableColumns}
        dataSource={errorData}
        pagination={{ ...pagination, hideOnSinglePage: true }}
        expandable={{
          expandedRowRender: (record: ErrorReportType) => {
            return (
              <>
                <p style={{ margin: '0px' }}>Firstname: {record.firstname}</p>
                <p style={{ margin: '0px' }}>Lastname: {record.lastname}</p>
                <p style={{ margin: '0px' }}>Email: {record.email}</p>
                <p style={{ margin: '0px' }}>Installer Version: {record.installerVersion}</p>
                <p style={{ margin: '0px' }}>Backend Version: {record.backendVersion}</p>
                <p style={{ margin: '0px' }}>Operating System: {record.operatingSystem}</p>
                <p style={{ margin: '0px' }}>Username: {record.username}</p>
                <p style={{ margin: '0px' }}>
                  Installation Location: {record.installationLocation}
                </p>
                <p style={{ margin: '0px' }}>Message: {record.message}</p>
              </>
            );
          },
        }}
        onRow={(record: ErrorReportType) => {
          return {
            onClick: () => setSelectedErrorReport(record),
            style: {
              opacity: selectedErrorReport && record !== selectedErrorReport ? 0.7 : 1,
            },
          };
        }}
        onChange={handleTableChange}
      />
      {selectedErrorReport && (
        <>
          <Title level={3} style={{ fontWeight: 600 }}>
            {t('selected_error_report')}
          </Title>
          <Form name='basic' labelCol={{ span: 2 }} labelWrap wrapperCol={{ span: 22 }}>
            <Form.Item label='Message'>
              <Input.TextArea
                style={{ height: '100px', whiteSpace: 'pre' }}
                readOnly
                value={selectedErrorReport.message}
              />
            </Form.Item>
            <Form.Item label='Log'>
              <Input.TextArea
                style={{ height: '400px', whiteSpace: 'pre' }}
                readOnly
                value={selectedErrorReport.log}
              />
            </Form.Item>
          </Form>
        </>
      )}
    </Wrapper>
  );
};

export default ErrorReports;

const Wrapper = styled.div`
  width: 100%;
`;

const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 1rem;
  margin-bottom: 1rem;
`;

const TableHeader = styled.div`
  display: flex;
  justify-content: end;
  align-items: center;
`;
