import api from './api';

export type Pagination = {
  /** the page to fetch as 0-based index */
  page?: number;
  /** the number of elements to fetch (on this page) */
  size?: number;
  /** the fields to sort the data on. May contain suffixes like ',asc' or ',desc' */
  sort?: Array<string>;
};

function paginationToQueryParams(pagination: Pagination, searchParams?: URLSearchParams): string {
  const query = searchParams ?? new URLSearchParams();
  if ('page' in pagination) {
    query.set('page', String(pagination.page));
  }
  if ('size' in pagination) {
    query.set('size', String(pagination.size));
  }
  if ('sort' in pagination) {
    for (const s of pagination.sort!) {
      query.append('sort', s);
    }
  }
  return query.toString();
}

export type SearchParameters = Array<{ key: string; value: string }>;

function searchToSearchQuery(search: SearchParameters, query?: URLSearchParams): string {
  let searchOptions = '';
  search.forEach((element: any, index: number) => {
    if (index > 0) searchOptions += ',';
    searchOptions += `like(${element.key},${element.value})`;
  });
  if (query) {
    query.append('search', `or(${searchOptions})`);
  }
  return encodeURIComponent(`or(${searchOptions})`).replaceAll('(', '%28').replaceAll(')', '%29');
}

function isId(arg: any): arg is number | string {
  return null != arg && undefined != arg && (typeof arg == 'number' || typeof arg == 'string');
}

function isPagination(arg: any): arg is Pagination {
  return (
    null != arg &&
    undefined != arg &&
    (typeof arg.page == 'number' || typeof arg.size == 'number' || Array.isArray(arg.sorting))
  );
}

function get(url: string, id: number | string): Promise<Response>;
function get(url: string, pagination?: Pagination, search?: string): Promise<Response>;
function get(url: string, pagination?: Pagination, search?: SearchParameters): Promise<Response>;
async function get(
  url: string,
  arg?: number | string | Pagination | SearchParameters,
  search?: string | SearchParameters,
) {
  const id = isId(arg) ? arg : undefined;
  const pagination = isPagination(arg) ? arg : undefined;
  const query = new URLSearchParams();
  if (pagination) {
    paginationToQueryParams(pagination, query);
  }
  if (search) {
    if (typeof search === 'string') {
      // if seach is a string treat it as 'raw' search query
      query.append('search', search);
    } else if (search.length > 0) {
      // if seach is a {key, value} list, build an or(...) query from it
      searchToSearchQuery(search, query);
    }
  }
  const queryString = query.values().next().value ? `?${query.toString()}` : '';
  const response = await fetch(`${api}${url}${id ? '/' + id : ''}${queryString}`, {
    credentials: 'include',
    mode: 'cors',
    method: 'GET',
    headers: {
      'X-XSRF-TOKEN': sessionStorage.getItem('X-XSRF-TOKEN') || '',
      'content-type': 'application/json',
    },
  });
  return response;
}

export default get;
