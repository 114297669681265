import { PageType, LicenseType } from '../types';
import put from '../crud/put';
import post from '../crud/post';
import get, { Pagination } from '../crud/get';
import deleteItem from '../crud/delete';

const API_URL = 'api/license';

export const addLicense = (body: LicenseType) => {
  return post(API_URL, body);
};

export const editLicense = (id: number | string, body: LicenseType) => {
  return put(API_URL, id, body);
};

export const getLicenses = async (pagination?: Pagination, search?: string) => {
  const searchParameters: any = [];
  if (search) {
    searchParameters.push({ key: 'company.name', value: search });
    searchParameters.push({ key: 'serial', value: search.toUpperCase() });
    searchParameters.push({ key: 'created', value: search });
  }
  const license = await get(API_URL, pagination, searchParameters);
  const data = (await license.json()) as any;
  return data as PageType<LicenseType>;
};

export const getLicensesByGroup = async (groupId: number | string, pagination?: Pagination) => {
  const licenses = await get(API_URL, pagination, `eq(group.id, ${groupId})`);
  const data = (await licenses.json()) as any;
  return data as PageType<LicenseType>;
};

export const getSingleLicense = async (id: number | string) => {
  const license = await get(API_URL, id);
  const data = (await license.json()) as any;
  return data as LicenseType;
};

export const deleteLicense = async (id: number | string) => {
  await deleteItem(API_URL, id);
};
