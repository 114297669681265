import { DownOutlined, RightOutlined } from '@ant-design/icons';
import { Affix, Typography } from 'antd';
import { CSSProperties, useState } from 'react';
import styled from 'styled-components';

const { Title } = Typography;

interface Props {
  title: string;
  defaultExpanded?: boolean;
  affix?: true;
  style?: CSSProperties;
  children: any;
  level?: 1 | 2 | 3 | 4 | 5;
}

const ExpandableTitle = ({ title, children, defaultExpanded, level = 2, style, affix }: Props) => {
  const [expanded, setExpanded] = useState(defaultExpanded ?? false);

  const titleElement = (
    <CustomTitle level={level} onClick={() => setExpanded(!expanded)} style={style}>
      {title}
      <IconWrapper>{expanded ? <DownOutlined /> : <RightOutlined />}</IconWrapper>
    </CustomTitle>
  );

  return (
    <Wrapper>
      {affix ? <Affix>{titleElement}</Affix> : titleElement}
      {expanded && children}
    </Wrapper>
  );
};

export default ExpandableTitle;

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const CustomTitle = styled(Title)`
  &:hover {
    cursor: pointer;
  }
  margin-top: 1rem;
  margin-bottom: 1rem !important;
  font-weight: 600;
  background: ${(props) => props.style?.background};
`;

const IconWrapper = styled.span`
  margin-left: 24px;
`;
