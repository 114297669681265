import { PageType, CompanyType } from '../types';
import put from '../crud/put';
import post from '../crud/post';
import get, { Pagination } from '../crud/get';
import deleteItem from '../crud/delete';

const API_URL = 'api/company';

export const addCompany = (body: CompanyType) => {
  return post(API_URL, body);
};

export const editCompany = (id: number | string, body: CompanyType) => {
  return put(API_URL, id, body);
};

export const getCompanies = async (pagination?: Pagination, search?: string) => {
  const searchParameters: any = [];
  if (search) {
    searchParameters.push({ key: 'name', value: search });
  }
  const companies = await get(API_URL, pagination, searchParameters);
  const data = (await companies.json()) as any;
  return data as PageType<CompanyType>;
};

export const getSingleCompany = async (id: number | string) => {
  const companies = await get(API_URL, id);
  const data = (await companies.json()) as any;
  return data as CompanyType;
};

export const deleteCompany = (id: number | string) => {
  deleteItem(API_URL, id);
};
