import type { MenuProps } from 'antd';
import { Menu } from 'antd';
import { useMatches, useNavigate } from 'react-router-dom';
import { routes } from '../router/routes';
import { CSSProperties } from 'react';

const menuItems: MenuProps['items'] = [
  {
    label: 'Home',
    key: routes.admin,
  },
  {
    label: 'publishing.one',
    key: routes.productinfo,
    children: [
      {
        label: 'Lizenzen',
        key: routes.licenses,
      },
      {
        label: 'Versionen',
        key: routes.releases,
      },
      {
        label: 'Gruppen',
        key: routes.groups,
      },
      {
        label: 'Kunden',
        key: routes.companies,
      },
    ],
  },
  {
    label: 'Einstellungen',
    key: routes.data,
    children: [
      {
        label: 'Fehlerberichte',
        key: routes.errorreport,
      },
      {
        label: 'Aktivierungen',
        key: routes.activations,
      },
      {
        label: 'Deaktivierungen',
        key: routes.deactivations,
      },
      {
        label: 'Textvorlagen',
        key: routes.texttemplates,
      },
      {
        label: 'Module',
        key: routes.modules,
      },
    ],
  },
];
const AdminNavigation = () => {
  const navigate = useNavigate();
  const matches = useMatches();

  const onMenuClick: MenuProps['onClick'] = (e) => {
    navigate(e.key);
  };

  const style: CSSProperties = {
    position: 'sticky',
    top: `70px`, // MUST match Header height (incl. margins)
    zIndex: 2,
  };

  return (
    <Menu
      style={style}
      mode='horizontal'
      selectedKeys={[matches.length > 1 ? '/' + matches[1].pathname.split('/')[1] : 'none']}
      onClick={onMenuClick}
      items={menuItems}
    />
  );
};

export default AdminNavigation;
