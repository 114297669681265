import SVG from 'react-inlinesvg';
import styled from 'styled-components';

import LogoImage from '../img/logo.svg';
import DropdownNavigation from './DropdownNavigation';
import { useAppSelector } from '../hooks';
import { LoginState } from '../redux/reducers/userReducer';
import { Link } from 'react-router-dom';

const Header = () => {
  const user = useAppSelector((state) => state.user);
  return (
    <Wrapper>
      <Container>
        <Link
          to={user.authorities.some((auth) => auth.authority === 'ROLE_ADMIN') ? '/admin' : '/'}
        >
          <Logo src={LogoImage} />
        </Link>

        {user.loggedIn === LoginState.LoggedIn && <DropdownNavigation userName={user.name} />}
      </Container>
    </Wrapper>
  );
};

export default Header;

const Wrapper = styled.div`
  position: sticky;
  top: 0px;
  z-index: 3;
  height: 70px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #001529;
`;

const Container = styled.div`
  display: flex;
  width: 100%;
  max-width: 1240px;
  justify-content: space-between;
  padding: 16px 0;
`;

const Logo = styled(SVG)`
  height: 38px;
`;
