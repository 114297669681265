import { MailOutlined } from '@ant-design/icons';
import { Button, Card, notification } from 'antd';
import ButtonGroup from 'antd/es/button/button-group';
import { useToken } from 'antd/es/theme/internal';
import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

export class FetchError extends Error {
  constructor(public readonly error: Error) {
    super(`Error while handling fetch result. Got status ${error.code}.`);
  }
}

export type Error = {
  code: number;
  body: { type: 'text'; value: string } | { type: 'json'; value: object };
};

export type ErrorProps = {
  title?: React.ReactNode;
  message?: React.ReactNode;
  data?: object;
};

export type UseFetch = {
  handleResponse(response: Promise<Response>, options?: { data: any }): Promise<Response>;
  error: null | Error;
  Error: React.FC<ErrorProps>;
};

function useFetch(): UseFetch {
  const { t } = useTranslation();
  const [, token] = useToken();
  const [error, setError] = useState<null | Error>(null);
  const [data, setData] = useState<any>(null);
  const handleResponse: UseFetch['handleResponse'] = useMemo(
    () => async (resp, options) => {
      if (undefined === resp) {
        console.error(
          '`handleResponse` received undefined instead of Promise<Response>. Did you forget to return the result of fetch(...) from a function?',
        );
        return;
      }

      if (options?.data) {
        setData(options.data);
      }

      const r = await resp;
      if (r.ok) {
        setError(null);
        notification.success({
          message: t('save_success'),
          placement: 'topRight',
        });
        return r;
      } else {
        const code = r.status;
        const text = await r.text();
        let e = error;
        try {
          const json = JSON.parse(text);
          e = { code, body: { type: 'json', value: json } };
          setError(e);
        } catch {
          e = { code, body: { type: 'text', value: text } };
          setError(e);
        }

        throw new FetchError(e);
        return undefined as any; // needed to please the typechecker
      }
    },
    [],
  );

  const component = useMemo(
    () =>
      function FetchError({ title, message, ...rest }: ErrorProps) {
        const onClose = () => setError(null);

        const requestData = rest.data ?? data;
        const mail = {
          subject: 'Fehler beim Speichern im Support Backend',
          body: [
            `Route: ${window.location.pathname}`,
            `data: ${requestData ? JSON.stringify(requestData, null, '  ') : 'not available'}`,
            `error: ${JSON.stringify(error, null, '  ')}`,
          ].join('\n\n'),
        };
        const mailHref = `mailto:support@publishing.one?subject=${encodeURIComponent(
          mail.subject,
        )}&body=${encodeURIComponent(mail.body)}`;

        const defaultTitle = `${t('error_while_saving', { status: error?.code })}`;
        const defaultMessage = (
          <>
            <details>
              <summary>{t('error_message')}</summary>
              <code>
                <pre style={{ whiteSpace: 'pre-wrap' }}>
                  {error?.body.type === 'text'
                    ? error.body.value
                    : JSON.stringify(error?.body.value, null, '  ')}
                </pre>
              </code>
            </details>
          </>
        );

        const extraElements = (
          <ButtonGroup>
            <Button type='default' danger href={mailHref}>
              <MailOutlined /> {t('help')}
            </Button>
            <Button
              type='primary'
              danger
              onClick={onClose}
              style={{ borderLeftColor: token.colorErrorBorder }}
            >
              {t('close')}
            </Button>
          </ButtonGroup>
        );

        return (
          error && (
            <Card
              title={title ?? defaultTitle}
              headStyle={{
                borderBottom: `1px solid ${token.colorErrorBorder}`,
              }}
              style={{
                margin: '12px 0px',
                backgroundColor: token.colorErrorBg,
                border: `1px solid ${token.colorErrorBorderHover}`,
              }}
              extra={extraElements}
            >
              {message ?? defaultMessage}
            </Card>
          )
        );
      },
    [error, data],
  );

  return {
    handleResponse,
    error,
    Error: component,
  } as UseFetch;
}

export default useFetch;
