import { ConfigProvider } from 'antd';
import { Provider } from 'react-redux';
import deDE from 'antd/locale/de_DE';
import store from './redux/store';
import dayjs from 'dayjs';
import 'dayjs/locale/de';
import AppRouter from './router/AppRouter';

dayjs.locale('de');

function App() {
  return (
    <ConfigProvider
      locale={deDE}
      componentSize='large'
      theme={{
        token: {
          colorPrimary: '#1DB954',
          colorError: '#DC2626',
          borderRadius: 8,
          colorInfo: '#009EE3',
          fontSizeHeading2: 28,
          fontSizeHeading3: 18,
        },
      }}
    >
      <Provider store={store}>
        <AppRouter />
      </Provider>
    </ConfigProvider>
  );
}

export default App;
