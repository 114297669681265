import api from './api';

const put = async (url: string, id: number | string, body: any) => {
  const response = await fetch(`${api}${url}/${id}`, {
    credentials: 'include',
    mode: 'cors',
    method: 'PUT',
    headers: {
      'X-XSRF-TOKEN': sessionStorage.getItem('X-XSRF-TOKEN') || '',
      'content-type': 'application/json',
    },
    body: JSON.stringify({ ...body }),
  });
  return response;
};

export async function putWithoutId(url: string, body: any) {
  const response = await fetch(`${api}${url}`, {
    credentials: 'include',
    mode: 'cors',
    method: 'PUT',
    headers: {
      'X-XSRF-TOKEN': sessionStorage.getItem('X-XSRF-TOKEN') || '',
      'content-type': 'application/json',
    },
    body: JSON.stringify({ ...body }),
  });
  return response;
}

export default put;
