import { Input, Typography, Col, Row, Divider } from 'antd';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { TextTemplateType } from '../core/types';
import { getUserLicense, getUserTextTemplates } from '../core/api/user';
import styled from 'styled-components';
import { routes } from '../router/routes';
import { useNavigate } from 'react-router-dom';
import RichtextViewer from '../components/RichtextViewer';
const { Text, Title, Paragraph } = Typography;

const BaseData = () => {
  const { t } = useTranslation();
  const [textTemplates, setTextTemplates] = useState<TextTemplateType>();
  const [license, setLicense] = useState<any>({});
  const navigate = useNavigate();
  useMemo(() => {
    const fetchData = async () => {
      const templates = await getUserTextTemplates();
      setTextTemplates(templates);
      const userLicense = await getUserLicense();
      setLicense(userLicense.data);
    };
    fetchData().catch((error) => {
      console.log(error);
      navigate(routes.user_not_assigned);
    });
  }, []);
  return (
    <Wrapper>
      {license.hotfolder && license.hotfolder.active && (
        <>
          <Title level={2}>{t('hotfolder')}</Title>
          {license.hotfolder.data.map((entry: any, index: number) => (
            <div key={'hotfolder_' + index}>
              {entry.title && <Title level={3}>{entry.title}</Title>}

              {entry.show_description && (
                <Row>
                  <Col span={20}>
                    <Text>
                      <RichtextViewer value={textTemplates?.hotfolder.description} />
                    </Text>
                  </Col>
                </Row>
              )}
              {entry.addition && (
                <Row>
                  <Col span={20}>
                    <Paragraph>
                      <RichtextViewer value={entry.addition} />
                    </Paragraph>
                  </Col>
                </Row>
              )}
              <Row align={'middle'} gutter={6} style={{ marginBottom: '12px' }}>
                <Col span={4}>
                  <Label>{t('target')}</Label>
                </Col>
                <Col span={15}>
                  <StyledInput value={entry.target} readOnly />
                </Col>
                <Col span={1}>
                  <Text style={{ fontSize: '18px' }} copyable={{ text: entry.target }} />
                </Col>
              </Row>
              <Row align={'middle'} gutter={6} style={{ marginBottom: '12px' }}>
                <Col span={4}>
                  <Label>{t('ftp_username')}</Label>
                </Col>
                <Col span={15}>
                  <StyledInput value={entry.ftp_username} readOnly />
                </Col>
                <Col span={1}>
                  <Text style={{ fontSize: '18px' }} copyable={{ text: entry.ftp_username }} />
                </Col>
              </Row>
              <Row align={'middle'} gutter={6} style={{ marginBottom: '12px' }}>
                <Col span={4}>
                  <Label>{t('password')}</Label>
                </Col>
                <Col span={15}>
                  <StyledInput value={entry.password} readOnly />
                </Col>
                <Col span={1}>
                  <Text style={{ fontSize: '18px' }} copyable={{ text: entry.password }} />
                </Col>
              </Row>
              <Row align={'middle'} gutter={6}>
                <Col span={4}>
                  <Label>{t('directory')}</Label>
                </Col>
                <Col span={15}>
                  <StyledInput value={entry.directory} readOnly />
                </Col>
                <Col span={1}>
                  <Text style={{ fontSize: '18px' }} copyable={{ text: entry.directory }} />
                </Col>
              </Row>
              {index < license.hotfolder.data.length - 1 && <Divider />}
            </div>
          ))}
          <Divider />
        </>
      )}
      {license.userdata && license.userdata.active && (
        <>
          <Title level={2}>{t('userdata')}</Title>
          {license.userdata.data.map((entry: any, index: number) => (
            <div key={'userdata_' + index}>
              {entry.title && <Title level={3}>{entry.title}</Title>}

              {entry.show_description && (
                <Row>
                  <Col span={20}>
                    <Text>
                      <RichtextViewer value={textTemplates?.userdata.description} />
                    </Text>
                  </Col>
                </Row>
              )}
              {entry.addition && (
                <Row>
                  <Col span={20}>
                    <Paragraph>
                      <RichtextViewer value={entry.addition} />
                    </Paragraph>
                  </Col>
                </Row>
              )}
              <Row align={'middle'} gutter={6} style={{ marginBottom: '12px' }}>
                <Col span={4}>
                  <Label>{t('target')}</Label>
                </Col>
                <Col span={15}>
                  <StyledInput value={entry.target} readOnly />
                </Col>
                <Col span={1}>
                  <Text style={{ fontSize: '18px' }} copyable={{ text: entry.target }} />
                </Col>
              </Row>
              <Row align={'middle'} gutter={6} style={{ marginBottom: '12px' }}>
                <Col span={4}>
                  <Label>{t('ftp_username')}</Label>
                </Col>
                <Col span={15}>
                  <StyledInput value={entry.ftp_username} readOnly />
                </Col>
                <Col span={1}>
                  <Text style={{ fontSize: '18px' }} copyable={{ text: entry.ftp_username }} />
                </Col>
              </Row>
              <Row align={'middle'} gutter={6} style={{ marginBottom: '12px' }}>
                <Col span={4}>
                  <Label>{t('password')}</Label>
                </Col>
                <Col span={15}>
                  <StyledInput value={entry.password} readOnly />
                </Col>
                <Col span={1}>
                  <Text style={{ fontSize: '18px' }} copyable={{ text: entry.password }} />
                </Col>
              </Row>
              <Row align={'middle'} gutter={6}>
                <Col span={4}>
                  <Label>{t('directory')}</Label>
                </Col>
                <Col span={15}>
                  <StyledInput value={entry.directory} readOnly />
                </Col>
                <Col span={1}>
                  <Text style={{ fontSize: '18px' }} copyable={{ text: entry.directory }} />
                </Col>
              </Row>
              {index < license.hotfolder.data.length - 1 && <Divider />}
            </div>
          ))}
          <Divider />
        </>
      )}
      {license.productdata && license.productdata.active && (
        <>
          <Title level={2}>{t('productdata')}</Title>
          {license.productdata.data.map((entry: any, index: number) => (
            <div key={'productdata_' + index}>
              {entry.title && <Title level={3}>{entry.title}</Title>}

              {entry.show_description && (
                <Row>
                  <Col span={20}>
                    <Text>
                      <RichtextViewer value={textTemplates?.productdata.description} />
                    </Text>
                  </Col>
                </Row>
              )}
              {entry.addition && (
                <Row>
                  <Col span={20}>
                    <Paragraph>
                      <RichtextViewer value={entry.addition} />
                    </Paragraph>
                  </Col>
                </Row>
              )}
              <Row align={'middle'} gutter={6} style={{ marginBottom: '12px' }}>
                <Col span={4}>
                  <Label>{t('target')}</Label>
                </Col>
                <Col span={15}>
                  <StyledInput value={entry.target} readOnly />
                </Col>
                <Col span={1}>
                  <Text style={{ fontSize: '18px' }} copyable={{ text: entry.target }} />
                </Col>
              </Row>
              <Row align={'middle'} gutter={6} style={{ marginBottom: '12px' }}>
                <Col span={4}>
                  <Label>{t('ftp_username')}</Label>
                </Col>
                <Col span={15}>
                  <StyledInput value={entry.ftp_username} readOnly />
                </Col>
                <Col span={1}>
                  <Text style={{ fontSize: '18px' }} copyable={{ text: entry.ftp_username }} />
                </Col>
              </Row>
              <Row align={'middle'} gutter={6} style={{ marginBottom: '12px' }}>
                <Col span={4}>
                  <Label>{t('password')}</Label>
                </Col>
                <Col span={15}>
                  <StyledInput value={entry.password} readOnly />
                </Col>
                <Col span={1}>
                  <Text style={{ fontSize: '18px' }} copyable={{ text: entry.password }} />
                </Col>
              </Row>
              <Row align={'middle'} gutter={6}>
                <Col span={4}>
                  <Label>{t('directory')}</Label>
                </Col>
                <Col span={15}>
                  <StyledInput value={entry.directory} readOnly />
                </Col>
                <Col span={1}>
                  <Text style={{ fontSize: '18px' }} copyable={{ text: entry.directory }} />
                </Col>
              </Row>
              {index < license.hotfolder.data.length - 1 && <Divider />}
            </div>
          ))}
          <Divider />
        </>
      )}

      {license.app_backend && license.app_backend.active && (
        <>
          <Title level={2}>{t('app_backend')}</Title>

          {license.app_backend.title && <Title level={3}>{license.app_backend.title}</Title>}

          {license.app_backend.show_description && (
            <Row>
              <Col span={20}>
                <Text>
                  <RichtextViewer value={textTemplates?.app_backend.description} />
                </Text>
              </Col>
            </Row>
          )}

          {license.app_backend.addition && (
            <Row>
              <Col span={20}>
                <Paragraph>
                  <RichtextViewer value={license.app_backend.addition} />
                </Paragraph>
              </Col>
            </Row>
          )}

          <Row align={'middle'} gutter={6} style={{ marginBottom: '12px' }}>
            <Col span={4}>
              <Label>{t('app_administration')}</Label>
            </Col>
            <Col span={15}>
              <StyledInput value={license.app_backend.administration} readOnly />
            </Col>
            <Col span={1}>
              <Text
                style={{ fontSize: '18px' }}
                copyable={{ text: license.app_backend.administration }}
              />
            </Col>
          </Row>
          <Row align={'middle'} gutter={6} style={{ marginBottom: '12px' }}>
            <Col span={4}>
              <Label>{t('username')}</Label>
            </Col>
            <Col span={15}>
              <StyledInput value={license.app_backend.username} readOnly />
            </Col>
            <Col span={1}>
              <Text
                style={{ fontSize: '18px' }}
                copyable={{ text: license.app_backend.username }}
              />
            </Col>
          </Row>
          <Row align={'middle'} gutter={6}>
            <Col span={4}>
              <Label>{t('password')}</Label>
            </Col>
            <Col span={15}>
              <StyledInput value={license.app_backend.password} readOnly />
            </Col>
            <Col span={1}>
              <Text
                style={{ fontSize: '18px' }}
                copyable={{ text: license.app_backend.password }}
              />
            </Col>
          </Row>
        </>
      )}
    </Wrapper>
  );
};

export default BaseData;

const Wrapper = styled.div`
  width: 100%;
  margin-bottom: 24px;
`;

const StyledInput = styled(Input)`
  background-color: #f8fafc;
`;

const Label = styled(Text)`
  float: right;
  margin-right: 12px;
`;
