import { Input, Typography, Col, Row, Divider } from 'antd';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { TextTemplateType } from '../core/types';
import { getUserLicense, getUserTextTemplates } from '../core/api/user';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { routes } from '../router/routes';
import RichtextViewer from '../components/RichtextViewer';

const { Text, Title, Paragraph } = Typography;

const Productinfo = () => {
  const { t } = useTranslation();
  const [textTemplates, setTextTemplates] = useState<TextTemplateType>();
  const [license, setLicense] = useState<any>({});
  const navigate = useNavigate();
  useMemo(() => {
    const fetchData = async () => {
      const templates = await getUserTextTemplates();

      const userLicense = await getUserLicense();
      setTextTemplates(templates);
      setLicense(userLicense.data);
    };
    fetchData().catch((error) => {
      console.log(error);
      navigate(routes.user_not_assigned);
    });
  }, []);

  const exchangePlaceholderValues = (text: string, changeValues: any) => {
    let changedText = text;
    console.log(changeValues);
    if (changeValues.target.length > 0)
      changedText = changedText.replaceAll('$ZIEL', changeValues.target);
    if (changeValues.ftp_username.length > 0)
      changedText = changedText.replaceAll('$BENUTZERNAME', changeValues.ftp_username);
    if (changeValues.password.length > 0)
      changedText = changedText.replaceAll('$PASSWORT', changeValues.password);
    if (changeValues.directory.length > 0)
      changedText = changedText.replaceAll('$VERZEICHNIS', changeValues.directory);
    return changedText;
  };

  return (
    <Wrapper>
      {license.backend_server && license.backend_server.active && (
        <>
          <Title level={2}>{t('backend_server')}</Title>

          {license.backend_server.title && <Title level={3}>{license.backend_server.title}</Title>}

          {license.backend_server.show_description && (
            <Row>
              <Col span={20}>
                <Text>
                  <RichtextViewer value={textTemplates?.backend_server.description} />
                </Text>
              </Col>
            </Row>
          )}

          {license.backend_server.addition && (
            <Row>
              <Col span={20}>
                <Paragraph>
                  <RichtextViewer value={license.backend_server.addition} />
                </Paragraph>
              </Col>
            </Row>
          )}

          <Row align={'middle'} gutter={6} style={{ marginBottom: '12px' }}>
            <Col span={4}>
              <Label>{t('backend_server')}</Label>
            </Col>
            <Col span={15}>
              <StyledInput value={license.backend_server.url} readOnly />
            </Col>
            <Col span={1}>
              <Text style={{ fontSize: '18px' }} copyable={{ text: license.backend_server.url }} />
            </Col>
          </Row>

          <Divider />
        </>
      )}
      {license.hosting && license.hosting.showToCustomer && (
        <>
          <Title level={2}>{t('hosting')}</Title>
          {license.hosting.data.map((entry: any, index: number) => (
            <div key={'hosting_' + index}>
              {entry.title && <Title level={3}>{entry.title}</Title>}

              {entry.show_description && (
                <Row>
                  <Col span={20}>
                    <Text>
                      <RichtextViewer
                        value={
                          textTemplates?.hosting.description
                            ? exchangePlaceholderValues(textTemplates.hosting.description, entry)
                            : ''
                        }
                      />
                    </Text>
                  </Col>
                </Row>
              )}
              {entry.addition && (
                <Row>
                  <Col span={20}>
                    <Paragraph>
                      <RichtextViewer
                        value={
                          entry.addition ? exchangePlaceholderValues(entry.addition, entry) : ''
                        }
                      />
                    </Paragraph>
                  </Col>
                </Row>
              )}
              {index < license.hosting.data.length - 1 && <Divider />}
            </div>
          ))}
          <Divider />
        </>
      )}
      {license.kiosk && license.kiosk.active && (
        <>
          <Title level={2}>{t('kiosk')}</Title>
          {license.kiosk.data.map((entry: any, index: number) => (
            <div key={'kiosk_' + index}>
              {entry.title && <Title level={3}>{entry.title}</Title>}

              {entry.show_description && (
                <Row>
                  <Col span={20}>
                    <Text>
                      <RichtextViewer value={textTemplates?.kiosk.description} />
                    </Text>
                  </Col>
                </Row>
              )}
              {entry.addition && (
                <Row>
                  <Col span={20}>
                    <Paragraph>
                      <RichtextViewer value={entry.addition} />
                    </Paragraph>
                  </Col>
                </Row>
              )}
              <Row align={'middle'} gutter={6}>
                <Col span={4}>
                  <Label>{t('kiosk_link')}</Label>
                </Col>
                <Col span={15}>
                  <StyledInput value={entry.url} readOnly />
                </Col>
                <Col span={1}>
                  <Text style={{ fontSize: '18px' }} copyable={{ text: entry.url }} />
                </Col>
              </Row>
              {index < license.kiosk.data.length - 1 && <Divider />}
            </div>
          ))}
          <Divider />
        </>
      )}
      {license.app && license.app.active && (
        <>
          <Title level={2}>{t('app')}</Title>

          {license.app.data.map((entry: any, index: any) => (
            <div key={'app_' + index}>
              {entry.title && <Title level={4}>{entry.title}</Title>}

              {entry.show_description && (
                <Row>
                  <Col span={20}>
                    <Text>
                      <RichtextViewer value={textTemplates?.app.description} />
                    </Text>
                  </Col>
                </Row>
              )}
              {entry.addition && (
                <Row>
                  <Col span={20}>
                    <Paragraph>
                      <RichtextViewer value={entry.addition} />
                    </Paragraph>
                  </Col>
                </Row>
              )}
              <Row align={'middle'} gutter={6} style={{ marginBottom: '12px' }}>
                <Col span={4}>
                  <Label>{t('ios_app')}</Label>
                </Col>
                <Col span={15}>
                  <StyledInput value={entry.ios_app} readOnly />
                </Col>
                <Col span={1}>
                  <Text style={{ fontSize: '18px' }} copyable={{ text: entry.ios_app }} />
                </Col>
              </Row>
              <Row align={'middle'} gutter={6}>
                <Col span={4}>
                  <Label>{t('android_app')}</Label>
                </Col>
                <Col span={15}>
                  <StyledInput value={entry.android_app} readOnly />
                </Col>
                <Col span={1}>
                  <Text style={{ fontSize: '18px' }} copyable={{ text: entry.android_app }} />
                </Col>
              </Row>
              {index < license.app.data.length - 1 && <Divider />}
            </div>
          ))}
        </>
      )}
    </Wrapper>
  );
};

export default Productinfo;

const Wrapper = styled.div`
  width: 100%;
  margin-bottom: 24px;
`;

const StyledInput = styled(Input)`
  background-color: #f8fafc;
`;

const Label = styled(Text)`
  float: right;
  margin-right: 12px;
`;
