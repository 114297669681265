import get from '../crud/get';

const API_URL = 'user/';

export const getUserLicense = async () => {
  const license = await get(API_URL + 'license');
  const data = (await license.json()) as any;
  return data;
};

export const getUserTextTemplates = async () => {
  const template = await get(API_URL + 'textTemplate');
  const data = (await template.json()) as any;
  return data.data;
};
