import { PageType, GroupType } from '../types';
import put from '../crud/put';
import post from '../crud/post';
import get, { Pagination } from '../crud/get';
import deleteItem from '../crud/delete';

const API_URL = 'api/group';

export const addGroup = (body: GroupType) => {
  return post(API_URL, body);
};

export const editGroup = (id: number | string, body: GroupType) => {
  return put(API_URL, id, body);
};

export const getGroups = async (pagination?: Pagination, search?: string) => {
  const searchParameters: any = [];
  if (search) {
    searchParameters.push({ key: 'name', value: search });
    searchParameters.push({ key: 'description', value: search });
  }
  const groups = await get(API_URL, pagination, searchParameters);
  const data = (await groups.json()) as any;
  return data as PageType<GroupType>;
};

export const getSingleGroup = async (id: number | string) => {
  const groups = await get(API_URL, id);
  const data = (await groups.json()) as any;
  return data as GroupType;
};

export const deleteGroup = async (id: number | string) => {
  await deleteItem(API_URL, id);
};
