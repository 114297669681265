import { Button, Form, Input, Checkbox, Space, Row, Col, Alert } from 'antd';
import Title from 'antd/es/typography/Title';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';

import { CompanyType } from '../core/types';
import { useMemo, useState } from 'react';

import { routes } from '../router/routes';
import styled from 'styled-components';
import { addCompany, editCompany, getSingleCompany } from '../core/api/company';
import { SelectOutlined } from '@ant-design/icons';
import useFetch from '../core/api/useFetch';

const CompanyEdit = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const [form] = Form.useForm();
  const { handleResponse, Error } = useFetch();
  const [companyUsers, setCompanyUsers] = useState<string[]>([]);
  const [addUser, setAddUser] = useState<string>('');
  const navigate = useNavigate();
  const initialValues = {
    active: true,
    name: '',
  };

  const onFinish = async (values: any) => {
    const body: CompanyType = {
      name: values.name,
      organisationId: values.organisation_id,
      active: values.active,
      users: companyUsers,
    };
    if (id) {
      body.id = parseInt(id);
      await handleResponse(editCompany(id, body), { data: body });
    } else {
      await handleResponse(addCompany(body), { data: body });
    }
    navigate(routes.companies, { replace: true });
  };

  const onAddUserToCompany = () => {
    setCompanyUsers([addUser, ...companyUsers]);
    setAddUser('');
  };

  useMemo(() => {
    if (id) {
      const fetchCompanies = async () => {
        const company = await getSingleCompany(id);
        const fieldValues = {
          name: company.name,
          organisation_id: company.organisationId,
          active: company.active,
        };
        form.setFieldsValue(fieldValues);
        setCompanyUsers(company.users!);
      };

      fetchCompanies().catch(console.error);
    }
  }, []);

  return (
    <Wrapper>
      <Error />
      <Title level={2}>{id ? t('edit_company') : t('new_company')}</Title>
      <Space style={{ marginBottom: '24px' }}>
        <Alert message={t('save_warning')} type='warning' showIcon />
      </Space>
      <Form
        form={form}
        onFinish={onFinish}
        initialValues={initialValues}
        name='basic'
        labelCol={{ span: 4 }}
        labelWrap
        wrapperCol={{ span: 16 }}
      >
        <Form.Item
          label={t('name')}
          name='name'
          rules={[{ required: true, message: 'Bitte tragen Sie den Namen des Kunden ein' }]}
        >
          <Input placeholder={t('name') || ''} />
        </Form.Item>
        <Form.Item
          label={t('organisation_id')}
          name='organisation_id'
          rules={[{ required: true, message: 'Bitte geben Sie dem Kunden eine ID' }]}
        >
          <Input placeholder={t('organisation_id') || ''} />
        </Form.Item>

        <Form.Item label={t('active')} name='active' valuePropName='checked'>
          <Checkbox />
        </Form.Item>
        <Form.Item label={t('user_id')}>
          <Space direction='vertical' style={{ width: '100%' }}>
            <Row gutter={6}>
              <Col span={18}>
                <Input
                  value={addUser}
                  onChange={(e) => setAddUser(e.target.value)}
                  style={{ flex: 1 }}
                />
              </Col>
              <Col span={4}>
                <Button onClick={onAddUserToCompany}>{t('add')}</Button>
              </Col>
            </Row>
            <Space direction='vertical' style={{ width: '100%' }}>
              {companyUsers.map((user) => (
                <Button
                  type='dashed'
                  key='user'
                  href={`https://auth.publishing.one/admin/master/console/#/p1-dev/users/${user}/settings`}
                  target='_blank'
                  style={{
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                  }}
                >
                  {user} <SelectOutlined style={{ marginLeft: '24px' }} />
                </Button>
              ))}
            </Space>
          </Space>
        </Form.Item>

        <Form.Item>
          <Space>
            <Button type='primary' htmlType='submit'>
              {t('save')}
            </Button>
            <Button htmlType='button' onClick={() => navigate(-1)}>
              {t('cancel')}
            </Button>
          </Space>
        </Form.Item>
      </Form>
    </Wrapper>
  );
};

export default CompanyEdit;

const Wrapper = styled.div`
  width: 100%;
`;
