import { Typography, Pagination, Skeleton } from 'antd';
import { forwardRef, useEffect, useImperativeHandle, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import ExpandableTitle from '../components/ExpandableTitle';
import { ReleaseNotesType, getReleasenotes } from '../core/api/release_notes';
import dayjs from 'dayjs';
import { ReleaseNoteType, ReleaseTypeType } from '../core/types';

const { Title } = Typography;

const ReleaseNotes = () => {
  const { t } = useTranslation();
  const [releaseNotesData, setReleaseNotesData] = useState<ReleaseNotesType | null>(null);
  const [paginationData, setPaginationData] = useState({
    SOFTWARE: { current: 0, pageSize: 5 },
    APP: { current: 0, pageSize: 5 },
    KIOSK: { current: 0, pageSize: 5 },
  });
  const refs = {
    SOFTWARE: useRef<ReleaseNotesListRef | null>(null),
    APP: useRef<ReleaseNotesListRef | null>(null),
    KIOSK: useRef<ReleaseNotesListRef | null>(null),
  };

  useEffect(() => {
    const fetchData = async () => {
      const releaseNotes = await getReleasenotes();
      setReleaseNotesData(releaseNotes);
    };
    fetchData().catch(console.error);
  }, []);

  const onPaginationChange = (type: ReleaseTypeType, current: number, pageSize: number) => {
    setPaginationData({
      ...paginationData,
      [type]: { current, pageSize },
    });

    if (refs[type].current) {
      refs[type].current!.scrollToTop();
    }
  };

  const onSoftwarePaginationChange = useMemo(() => {
    return onPaginationChange.bind(null, 'SOFTWARE');
  }, []);

  const onAppPaginationChange = useMemo(() => {
    return onPaginationChange.bind(null, 'APP');
  }, []);

  const onKioskPaginationChange = useMemo(() => {
    return onPaginationChange.bind(null, 'KIOSK');
  }, []);

  return (
    <Wrapper>
      <TitleWrapper>
        <Title level={2}>{t('release_notes')}</Title>
      </TitleWrapper>
      {!releaseNotesData ? (
        <ReleaseNotesListSkeleton />
      ) : (
        <>
          {releaseNotesData.SOFTWARE.length > 0 && (
            <ReleaseNotesList
              ref={refs.SOFTWARE}
              title='publishing.one Software'
              defaultExpanded
              data={releaseNotesData?.SOFTWARE}
              pagination={paginationData.SOFTWARE}
              onPaginationChange={onSoftwarePaginationChange}
            />
          )}
          {releaseNotesData.APP.length > 0 && (
            <ReleaseNotesList
              ref={refs.APP}
              title='App'
              data={releaseNotesData?.APP}
              pagination={paginationData.APP}
              onPaginationChange={onAppPaginationChange}
            />
          )}
          {releaseNotesData.KIOSK.length > 0 && (
            <ReleaseNotesList
              ref={refs.KIOSK}
              title='Kiosk'
              data={releaseNotesData?.KIOSK}
              pagination={paginationData.KIOSK}
              onPaginationChange={onKioskPaginationChange}
            />
          )}
        </>
      )}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  width: 100%;
`;

const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const ReleaseNotesListSkeleton = () => (
  <>
    <ReleaseNoteCard>
      <Skeleton />
    </ReleaseNoteCard>
    <ReleaseNoteCard>
      <Skeleton />
    </ReleaseNoteCard>
  </>
);

type ReleaseNotesListProps = {
  ref?: React.Ref<ReleaseNotesListRef>;
  title: string;
  data?: ReleaseNoteType[];
  pagination: { current: number; pageSize: number };
  defaultExpanded?: boolean;
  onPaginationChange(page: number, pageSize: number): any;
};

type ReleaseNotesListRef = {
  scrollToTop(): any;
};

const ReleaseNotesList = forwardRef(function ReleaseNotesList(
  props: ReleaseNotesListProps,
  ref: any,
) {
  const { title, data, pagination, defaultExpanded } = props;
  const headerRef = useRef<Element>(null);
  const dataStart = pagination.current * pagination.pageSize;
  const dataEnd = dataStart + pagination.pageSize;

  useImperativeHandle<ReleaseNotesListRef, ReleaseNotesListRef>(ref, () => ({
    scrollToTop() {
      headerRef?.current?.scrollIntoView({ behavior: 'smooth', block: 'center' });
    },
  }));

  const onPaginationChange = useMemo(
    () => (page: number, pageSize: number) => props.onPaginationChange(page - 1, pageSize),
    [],
  );

  return (
    <ExpandableTitle
      title={title}
      level={3}
      defaultExpanded={defaultExpanded}
      style={{
        position: 'sticky',
        top: 'calc(70px + 45px)', // MUST match height of Header + UserNavigation (incl margins)
        zIndex: 1, // MUST be less than UserNavigation's z-index
        background: 'white',
        margin: 0,
        paddingTop: '0.83em',
        paddingBottom: '0.5em',
      }}
    >
      <a ref={headerRef as any} />
      {data?.slice(dataStart, dataEnd)?.map((releaseNote) => (
        <ReleaseNoteEntry key={releaseNote.id} {...releaseNote} />
      ))}
      <PullRight>
        <Pagination
          current={pagination.current + 1}
          pageSize={pagination.pageSize}
          pageSizeOptions={[5, 10, 20]}
          total={data?.length ?? 0}
          hideOnSinglePage
          onChange={onPaginationChange}
        />
      </PullRight>
    </ExpandableTitle>
  );
});

const PullRight = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: end;
  align-items: center;
`;

const ReleaseNoteEntry = (props: ReleaseNoteType) => {
  const { t } = useTranslation();
  const releaseDate = dayjs(props.releasedAt).format('DD.MM.YYYY');
  const title = `VERSION ${props.number} (${t('released_at').toLowerCase()} ${releaseDate})`;
  return (
    <ReleaseNoteCard>
      <strong>{title}</strong>
      <p dangerouslySetInnerHTML={{ __html: props.releaseNotes }} />
    </ReleaseNoteCard>
  );
};

const ReleaseNoteCard = styled.div`
  margin: 12px 0px;
  padding: 24px;
  border-radius: 8px;
  color: #52647b;
  background-color: #f2f4f4;
`;

export default ReleaseNotes;
