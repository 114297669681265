import { Button, Result } from 'antd';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

const UserNotAssigned = () => {
  const { t } = useTranslation();
  return (
    <Wrapper>
      <Result
        status='warning'
        title={t('no_access')}
        subTitle={t('contact_support_description')}
        extra={
          <Button type='primary' href='mailto:support@publishing.one'>
            {t('contact_support_link')}
          </Button>
        }
      />
    </Wrapper>
  );
};

export default UserNotAssigned;

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;
