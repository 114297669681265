import { Alert, Button, Form, Input, Space } from 'antd';
import Title from 'antd/es/typography/Title';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';

import { GroupType, LicenseType } from '../core/types';
import { useMemo } from 'react';

import { routes } from '../router/routes';
import styled from 'styled-components';
import { addGroup, editGroup, getSingleGroup } from '../core/api/group';
import useFetch from '../core/api/useFetch';
import { getLicensesByGroup } from '../core/api/license';

const GroupEdit = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const [form] = Form.useForm();
  const licenses = Form.useWatch<LicenseType[]>('licenses', form);
  const navigate = useNavigate();
  const { handleResponse, Error } = useFetch();

  const initialValues = {
    name: '',
    description: '',
  };

  const onFinish = async (values: any) => {
    const body: GroupType = {
      name: values.name,
      description: values.description,
      releaseCycle: {
        id: 1,
      },
    };
    if (id) {
      body.id = parseInt(id);
      await handleResponse(editGroup(id, body), { data: body });
    } else {
      await handleResponse(addGroup(body), { data: body });
    }
    navigate(routes.groups, { replace: true });
  };

  useMemo(() => {
    if (id) {
      const fetchGroups = async () => {
        const group = await getSingleGroup(id);
        const fieldValues = {
          name: group.name,
          description: group.description,
        };
        form.setFieldsValue(fieldValues);
      };
      const fetchLicenses = async () => {
        const companyPage = await getLicensesByGroup(id, { size: 1e6, sort: ['company.name,asc'] });
        const fieldValues = {
          licenses: companyPage.content,
        };
        form.setFieldsValue(fieldValues);
      };

      fetchGroups().catch(console.error);
      fetchLicenses().catch(console.error);
    }
  }, []);

  return (
    <Wrapper>
      <Error />
      <Title level={2}>{id ? t('edit_group') : t('new_group')}</Title>
      <Space style={{ marginBottom: '24px' }}>
        <Alert message={t('save_warning')} type='warning' showIcon />
      </Space>
      <Form
        form={form}
        onFinish={onFinish}
        initialValues={initialValues}
        name='basic'
        labelCol={{ span: 4 }}
        labelWrap
        wrapperCol={{ span: 16 }}
      >
        <Form.Item
          label={t('name')}
          name='name'
          rules={[{ required: true, message: 'Bitte geben Sie der Gruppe einen Namen' }]}
        >
          <Input placeholder={t('name') || ''} />
        </Form.Item>
        <Form.Item label={t('description')} name='description'>
          <Input.TextArea />
        </Form.Item>
        <Form.Item
          label={t('companies') + (licenses?.length ? ` (${licenses.length})` : '')}
          name='licenses'
        >
          <GroupCustomers />
        </Form.Item>
        <Form.Item>
          <Space>
            <Button type='primary' htmlType='submit'>
              {t('save')}
            </Button>
            <Button htmlType='button' onClick={() => navigate(-1)}>
              {t('cancel')}
            </Button>
          </Space>
        </Form.Item>
      </Form>
    </Wrapper>
  );
};

function GroupCustomers(props: { value?: LicenseType[] }) {
  if (!props.value?.length) {
    return null;
  }

  return (
    <CompanyGroupList>
      {props.value?.map(({ company: { id, name } }) => (
        <li key={id}>
          <a href={`${routes.companies_manage}/${id}`}>{name}</a>
        </li>
      ))}
    </CompanyGroupList>
  );
}

const CompanyGroupList = styled.ul`
  max-height: 400px;
  overflow: auto;
`;

export default GroupEdit;

const Wrapper = styled.div`
  width: 100%;
`;
