import api from './api';

const post = async (url: string, body: any) => {
  const response = await fetch(`${api}${url}`, {
    credentials: 'include',
    mode: 'cors',
    method: 'POST',
    headers: {
      'X-XSRF-TOKEN': sessionStorage.getItem('X-XSRF-TOKEN') || '',
      'content-type': 'application/json',
    },
    body: JSON.stringify({ ...body }),
  });
  return response;
};

export default post;
