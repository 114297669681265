export const enum routes {
  home = '/',
  admin = '/admin',
  data = '/data',
  productinfo = '/productinfo',
  releasenotes = '/releasenotes',
  login = '/login',
  register = '/register',
  forgotpassword = 'forgotpassword',
  licenses = '/licenses',
  releases = '/releases',
  releases_manage = '/releases/manage',
  groups = '/groups',
  groups_manage = '/groups/manage',
  companies = '/companies',
  companies_manage = '/companies/manage',
  user = '/user',
  errorreport = '/errorreports',
  activations = '/activations',
  deactivations = '/deactivations',
  texttemplates = '/texttemplates',
  modules = '/modules',
  modules_manage = '/modules/manage',
  licenses_manage = '/licenses/manage',
  user_not_assigned = '/error/notassigned',
  user_license_invalid = '/error/license_invalid',
}
