import { TextTemplateType } from '../types';
import { putWithoutId } from '../crud/put';
import get, { Pagination } from '../crud/get';

const API_URL = 'api/textTemplate';

export const editTextTemplates = (body: TextTemplateType) => {
  return putWithoutId(API_URL, { data: body });
};

export const getTextTemplates = async (pagination?: Pagination) => {
  const templates = await get(API_URL, pagination);
  const data = (await templates.json()) as any;
  return data.data as TextTemplateType;
};
