import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import api from '../../core/crud/api';
import post from '../../core/crud/post';

const XSRF_HEADER = 'X-XSRF-TOKEN';

export enum LoginState {
  Unknown = 'Unknown',
  NotLoggedIn = 'NotLoggedIn',
  LoggedIn = 'LoggedIn',
}

interface LoginFailed {
  errormessage: string;
  loggedIn: LoginState;
}

interface UserState {
  loggedIn: LoginState;
  name: string;
  id: string;
  xsrfToken: string;
  authorities: { authority: string }[];
  admin: {
    view: string;
  };
}

const initialState: UserState = {
  loggedIn: LoginState.Unknown,
  name: '',
  id: '',
  xsrfToken: '',
  authorities: [],
  admin: {
    view: 'user',
  },
};

export const login = createAsyncThunk<UserState, void, { rejectValue: LoginFailed }>(
  'user/login',
  async (_thunkArg, thunkApi) => {
    const response = await fetch(`${api}user`, {
      credentials: 'include',
      mode: 'cors',
    });
    let xsrfHeader = '';
    if (response.ok) {
      xsrfHeader = response.headers.get(XSRF_HEADER) as string;
      sessionStorage.setItem(XSRF_HEADER, xsrfHeader);
    } else {
      console.log('Not logged In');
      return thunkApi.rejectWithValue({
        errormessage: 'Could not log in',
        loggedIn: LoginState.NotLoggedIn,
      });
    }
    const data = await response.json();
    data.loggedIn = LoginState.LoggedIn;
    data.xsrfToken = xsrfHeader;
    return data as UserState;
  },
);

export const logout = createAsyncThunk<null, void, { rejectValue: any }>(
  'user/logout',
  async (_thunkArg, thunkApi) => {
    const response = await post('logout', null);
    if (!response.ok) {
      const text = await response.text();
      return thunkApi.rejectWithValue(text);
    } else {
      return thunkApi.fulfillWithValue(null);
    }
  },
);

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    changeView(state, action) {
      state.admin.view = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(login.fulfilled, (state, action) => {
      state.loggedIn = action.payload.loggedIn;
      state.name = action.payload.name;
      state.id = action.payload.id;
      state.authorities = action.payload.authorities;
      state.xsrfToken = action.payload.xsrfToken;
    });
    builder.addCase(login.rejected, (state, { payload }) => {
      if (payload) {
        console.log(payload.errormessage);
        state.loggedIn = payload.loggedIn;
      } else {
        state.loggedIn = LoginState.Unknown;
      }
    });
    builder.addCase(logout.fulfilled, (state) => {
      state.loggedIn = LoginState.NotLoggedIn;
    });
  },
});

export const { changeView } = userSlice.actions;
export default userSlice.reducer;
