import { DeleteTwoTone } from '@ant-design/icons';
import { Modal, Typography, theme } from 'antd';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
const { Title, Text } = Typography;
const { useToken } = theme;

interface Props {
  type: string;
  isOpen: boolean;
  onOk: () => void;
  onCancel: () => void;
}

const DeleteModal = ({ type, isOpen, onOk, onCancel }: Props) => {
  const { token } = useToken();
  const { t } = useTranslation();
  return (
    <Modal
      open={isOpen}
      onOk={onOk}
      onCancel={onCancel}
      okText={t('delete')}
      okButtonProps={{ danger: true }}
      maskClosable
      centered
    >
      <CenterDiv>
        <DeleteTwoTone twoToneColor={token.colorError} style={{ fontSize: '3em' }} />
        <Title level={1}>{t('delete_title', { item: type })}</Title>
        <Text>{t('delete_descripion', { item: type })}</Text>
      </CenterDiv>
    </Modal>
  );
};

export default DeleteModal;

const CenterDiv = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-bottom: 24px;
`;
