import { Button, Select, Space, Table, Tag } from 'antd';
import { Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import type { ColumnsType } from 'antd/es/table';
import { useMemo, useState } from 'react';
import moment from 'moment';
import get from '../core/crud/get';
import styled from 'styled-components';
const { Text, Title } = Typography;
import api from '../core/crud/api';
import { Link } from 'react-router-dom';
import ExpandableTitle from '../components/ExpandableTitle';
import { DownloadType, ReleaseType, ReleaseTypeType } from '../core/types';
import { useNavigate } from 'react-router-dom';
import { routes } from '../router/routes';

interface FilteredRelease extends ReleaseType {
  key: string;
  downlods?: Array<DownloadType & { key: string }>;
}
/**
 * The kind of data the tables column definition expects
 *
 * This is a union of Release and Download, as for every release *and* every download of every
 * release a table row will be rendered, all from the same column definition.
 */
interface TableData extends Omit<FilteredRelease, 'type'>, Omit<DownloadType, 'type'> {
  key: string;
  type: ReleaseTypeType | DownloadType['type'];
}

const Home = () => {
  const [licenseData, setLicenseData]: any = useState();
  const [currentRelease, setCurrentRelease] = useState<ReleaseType[]>([]);
  const [releaseData, setReleaseData] = useState<ReleaseType[]>([]);
  const [downloadType, setDownloadTypeInternal] = useState<DownloadType['type'] | 'empty'>('empty');
  const [platform, setPlatformInternal] = useState<DownloadType['platform'] | 'empty'>('empty');
  const navigate = useNavigate();
  const { t } = useTranslation();

  useMemo(() => {
    const fetchData = async () => {
      const userOverview = await get('user/overview');
      if (!userOverview.ok) {
        throw userOverview;
      }
      const data = (await userOverview.json()) as any;
      setLicenseData(data.license);
      const olderReleases = data.releases;
      const firstRelease = olderReleases.shift();
      setCurrentRelease(firstRelease ? [firstRelease] : []);
      setReleaseData(olderReleases);
    };
    fetchData().catch(async (error) => {
      console.log(error);
      switch (error.status) {
        case 404: {
          navigate(routes.login);
          break;
        }
        case 401: {
          const data = await error.json();
          if (data.reason === 'LICENSE_INVALID') {
            navigate(routes.user_license_invalid, { state: { message: data.message } });
          } else {
            navigate(routes.user_license_invalid);
          }
          break;
        }
        case 500: {
          navigate(routes.user_not_assigned);
          break;
        }
      }
    });
  }, []);

  const [setDownloadType, setPlatform] = useMemo(() => {
    const setDownloadType = (d: typeof downloadType) => setDownloadTypeInternal(d ?? 'empty');
    const setPlatform = (p: typeof platform) => setPlatformInternal(p ?? 'empty');
    return [setDownloadType, setPlatform];
  }, []);

  // filter releases based on 'download type' and 'platform' user selection
  const [currentReleaseFiltered, releaseDataFiltered] = useMemo(() => {
    function filterDownloads(
      d: Required<ReleaseType>['downloads'][number] | null | undefined,
    ): boolean {
      return (
        !!d &&
        (downloadType === 'empty' ||
          downloadType === d.type ||
          (downloadType === 'SERVER' && d.type === 'FULL')) &&
        (platform === 'empty' || platform === d.platform)
      );
    }
    function mapDownloads(r: ReleaseType): (d: DownloadType) => DownloadType & { key: string } {
      return (d) => ({ ...d, key: r.id + d.href });
    }
    function mapRelease(r: ReleaseType): FilteredRelease {
      return {
        ...r,
        key: String(r.id),
        downloads: r.downloads?.filter(filterDownloads)?.map(mapDownloads(r)),
      };
    }
    return [currentRelease.map(mapRelease), releaseData.map(mapRelease)];
  }, [currentRelease, releaseData, downloadType, platform]);

  const currentSoftwareTableColumns: ColumnsType<TableData> = [
    {
      title: t('version'),
      dataIndex: 'number',
      key: 'number',
      width: '20%',
    },
    {
      title: t('name'),
      dataIndex: 'type',
      key: 'type',
      render: (_, { type }) =>
        ({
          SOFTWARE: t('release_type_software'),
          APP: t('release_type_app'),
          KIOSK: t('release_type_kiosk'),
          FULL: t('download_type_full'),
          CLIENT: t('download_type_client'),
          SERVER: t('download_type_server'),
          MANUAL: t('download_type_manual'),
          MANUAL_SERVER: t('download_type_manual_server'),
        }[type] ?? type),
    },
    {
      title: '',
      width: '5%',
      render: (_, { href }) => <>{href && <Tag color='green'>neu</Tag>}</>,
    },
    {
      title: t('platform'),
      dataIndex: 'platform',
      key: 'platform',
      width: '15%',
      render: (_, { platform }) =>
        platform ? t(platform!.toLowerCase() as Lowercase<typeof platform>) : null,
    },
    {
      title: '',
      dataIndex: 'href',
      key: 'href',
      width: '15%',
      render: (_, { href, externalHref }) => (
        <>
          {href && (
            <Button type='link'>
              {externalHref ? (
                <Link to={href} target='_blank'>
                  {t('open')}
                </Link>
              ) : (
                <Link to={`${api.replace(/\/$/, '')}/${href.replace(/^\//, '')}`}>
                  {t('download')}
                </Link>
              )}
            </Button>
          )}
        </>
      ),
    },
  ];

  const oldSoftwareTableColumns: ColumnsType<TableData> = [
    {
      title: t('version'),
      dataIndex: 'number',
      key: 'number',
      width: '20%',
    },
    {
      title: t('name'),
      dataIndex: 'type',
      key: 'type',
      render: (_, { type }) =>
        ({
          SOFTWARE: t('release_type_software'),
          APP: t('release_type_app'),
          KIOSK: t('release_type_kiosk'),
          FULL: t('download_type_full'),
          CLIENT: t('download_type_client'),
          SERVER: t('download_type_server'),
          MANUAL: t('download_type_manual'),
          MANUAL_SERVER: t('download_type_manual_server'),
        }[type] ?? type),
    },
    {
      title: '',
      width: '5%',
    },
    {
      title: t('platform'),
      dataIndex: 'platform',
      key: 'platform',
      width: '15%',
      render: (_, { platform }) =>
        platform ? t(platform!.toLowerCase() as Lowercase<typeof platform>) : null,
    },
    {
      title: '',
      dataIndex: 'href',
      key: 'href',
      width: '15%',
      render: (_, { href, externalHref }) => (
        <>
          {href && (
            <Button type='link'>
              {externalHref ? (
                <Link to={href} target='_blank'>
                  {t('open')}
                </Link>
              ) : (
                <Link to={`${api.replace(/\/$/, '')}/${href.replace(/^\//, '')}`}>
                  {t('download')}
                </Link>
              )}
            </Button>
          )}
        </>
      ),
    },
  ];

  /** Maps a table row to a unique key */
  const rowKey = (d: TableData) => d.key;

  return (
    <Wrapper>
      <Space direction='vertical' style={{ marginTop: '1rem', marginBottom: '4rem' }}>
        <Title style={{ fontWeight: 600 }} level={2}>
          {t('license_data')}
        </Title>
        {licenseData && (
          <Space size={24}>
            <Space direction='vertical'>
              <Text strong>{t('serial_number')}:</Text>
              <Text strong>{t('day_of_last_delivery')}:</Text>
              {licenseData.validTo && <Text strong>{t('date_of_termination')}:</Text>}
              <Text strong>{t('activations')}:</Text>
            </Space>
            <Space direction='vertical'>
              <Text copyable>{licenseData.serial}</Text>
              <Text>{moment(licenseData.updated).format('DD.MM.YYYY')}</Text>
              {licenseData.validTo && (
                <span>
                  <Text type='danger'>{moment(licenseData.validTo).format('DD.MM.YYYY')}</Text>
                  <Button
                    type='link'
                    href='mailto:support@publishing.one?subject=Kündigung%20widerrufen&body=Liebes%20Support-Team,%0Ahiermit%20widerrufe%20ich%20die%20Kündigung%20meines%20Vertrags%20Nr.%20[bitte einfügen].%20Wir%20möchten%20die%20publishing.one%20Software%20weiterhin%20nutzen.'
                  >
                    {t('edit_termination')}
                  </Button>
                </span>
              )}
              <Text>{`${licenseData.numberOfActivations}/${licenseData.maxNumberOfActivations}`}</Text>
            </Space>
          </Space>
        )}
      </Space>
      <Space direction='vertical' style={{ marginBottom: '4rem' }}>
        <Space
          direction='horizontal'
          style={{ display: 'grid', gridTemplateColumns: '1fr auto auto' }}
        >
          <Title style={{ fontWeight: 600, marginBottom: '1rem' }} level={3}>
            {t('current_software_version')}
          </Title>
          <Select
            allowClear
            style={{ width: 240 }}
            value={downloadType}
            onChange={setDownloadType}
            options={[
              { value: 'empty', label: t('installation_kind') },
              { value: 'FULL', label: t('full_installation') },
              { value: 'CLIENT', label: 'Client' },
              { value: 'SERVER', label: 'Server' },
            ]}
          />
          <Select
            allowClear
            style={{ width: 160 }}
            value={platform}
            onChange={setPlatform}
            options={[
              { value: 'empty', label: t('operating_system') },
              { value: 'WINDOWS', label: 'Windows' },
              { value: 'MACOS', label: 'macOS' },
              { value: 'LINUX', label: 'Linux' },
            ]}
          />
        </Space>
        {currentRelease.length > 0 && (
          <Table
            rowKey={rowKey}
            expandable={{
              childrenColumnName: 'downloads',
              defaultExpandAllRows: true,
            }}
            pagination={{ hideOnSinglePage: true }}
            columns={currentSoftwareTableColumns}
            dataSource={currentReleaseFiltered as TableData[]}
          />
        )}
      </Space>

      <ExpandableTitle level={3} title={t('older_software_versions')}>
        <Table
          columns={oldSoftwareTableColumns}
          rowKey={rowKey}
          expandable={{
            childrenColumnName: 'downloads',
            expandRowByClick: true,
          }}
          dataSource={releaseDataFiltered as TableData[]}
        />
      </ExpandableTitle>
    </Wrapper>
  );
};

export default Home;

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;
