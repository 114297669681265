import { Typography, Form, Space, Button, Alert, Skeleton } from 'antd';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
const { Title, Text, Paragraph } = Typography;
import { editTextTemplates, getTextTemplates } from '../core/api/texttemplates';
import { useMemo, useState } from 'react';
import { LicenseType, TextTemplateType } from '../core/types';
import TextEditor from '../components/TextEditor';
import useFetch from '../core/api/useFetch';
import { getLicenses } from '../core/api/license';
import { routes } from '../router/routes';

type VaryingLicenses = {
  app: LicenseType[];
  hosting: LicenseType[];
  kiosk: LicenseType[];
  user_data: LicenseType[];
  hotfolder: LicenseType[];
  app_backend: LicenseType[];
  productdata: LicenseType[];
  backend_server: LicenseType[];
};

const TextTemplates = () => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const [licenses, setLicenses] = useState<Array<LicenseType>>([]);
  const [ready, setReady] = useState(false);
  const { handleResponse, Error } = useFetch();
  const onFinish = async (values: any) => {
    const body: TextTemplateType = {
      backend_server: {
        description: values.backend_server_description,
      },
      hosting: {
        description: values.hosting_description,
      },
      kiosk: {
        description: values.kiosk_description,
      },
      hotfolder: {
        description: values.hotfolder_description,
      },
      userdata: {
        description: values.userdata_description,
      },
      productdata: {
        description: values.productdata_description,
      },
      app_backend: {
        description: values.app_backend_description,
      },
      app: {
        description: values.app_description,
      },
    };
    handleResponse(editTextTemplates(body));
  };

  useMemo(() => {
    const fetchTemplates = async () => {
      const templates = await getTextTemplates();
      const fieldValues = {
        backend_server_description: templates.backend_server.description,
        hosting_description: templates.hosting.description,
        kiosk_description: templates.kiosk.description,
        app_description: templates.app.description,
        hotfolder_description: templates.hotfolder.description,
        userdata_description: templates.userdata.description,
        productdata_description: templates.productdata.description,
        app_backend_description: templates.app_backend.description,
      };
      form.setFieldsValue(fieldValues);
    };

    const fetchLicenses = async () => {
      const licenses = await getLicenses({ size: 1e6 });
      setLicenses(licenses.content);
    };

    Promise.all([fetchTemplates().catch(console.error), fetchLicenses().catch(console.error)]).then(
      () => setReady(true),
    );
  }, []);

  const varyingLicenses = useMemo(() => {
    const accumulator: VaryingLicenses = {
      app: [],
      hosting: [],
      kiosk: [],
      user_data: [],
      hotfolder: [],
      app_backend: [],
      productdata: [],
      backend_server: [],
    };
    return licenses.reduce((acc, license) => {
      function isAddition(s: string) {
        return !!s && s.length && s !== '<p><br></p>';
      }
      const hasAppAddition = license?.data?.app?.data?.some((d: any) => isAddition(d?.addition));
      const hasHostingAddition = license?.data?.hosting?.data?.some((d: any) =>
        isAddition(d?.addition),
      );
      const hasKioskAddition = license?.data?.kiosk?.data?.some((d: any) =>
        isAddition(d?.addition),
      );
      const hasUserDataAddition = license?.data?.userdata?.data?.some((d: any) =>
        isAddition(d?.addition),
      );
      const hasHotfolderAddition = license?.data?.hotfolder?.data?.some((d: any) =>
        isAddition(d?.addition),
      );
      const hasProductDataAddition = license?.data?.productdata?.data?.some((d: any) =>
        isAddition(d?.addition),
      );
      const hasAppBackendAddition = isAddition(license?.data?.app_backend?.addition);
      const hasBackendServerAddition = isAddition(license?.data?.backend_server?.addition);
      return {
        app: [...acc.app, ...(hasAppAddition ? [license] : [])],
        hosting: [...acc.hosting, ...(hasHostingAddition ? [license] : [])],
        kiosk: [...acc.kiosk, ...(hasKioskAddition ? [license] : [])],
        user_data: [...acc.user_data, ...(hasUserDataAddition ? [license] : [])],
        hotfolder: [...acc.hotfolder, ...(hasHotfolderAddition ? [license] : [])],
        app_backend: [...acc.app_backend, ...(hasAppBackendAddition ? [license] : [])],
        productdata: [...acc.productdata, ...(hasProductDataAddition ? [license] : [])],
        backend_server: [...acc.backend_server, ...(hasBackendServerAddition ? [license] : [])],
      };
    }, accumulator);
  }, [licenses]);

  return (
    <Wrapper>
      <Error />
      <Title level={2}>{t('texttemplates')}</Title>
      <Space style={{ marginBottom: '24px' }}>
        <Alert message={t('save_warning')} type='warning' showIcon />
      </Space>
      <Form
        form={form}
        onFinish={onFinish}
        name='basic'
        labelCol={{ span: 4 }}
        labelWrap
        wrapperCol={{ span: 16 }}
      >
        <TextBlock
          ready={ready}
          title={t('backend_server')}
          name='backend_server_description'
          licenses={varyingLicenses.backend_server}
        />
        <TextBlock
          ready={ready}
          title={t('hosting')}
          description={
            <Paragraph>
              <Title level={5}>{t('placeholder_value_examples')}</Title>
              <Text>{t('hosting_placeholder_value_explanation')}</Text>
            </Paragraph>
          }
          name='hosting_description'
          licenses={varyingLicenses.hosting}
        />
        <TextBlock
          ready={ready}
          title={t('kiosk')}
          name='kiosk_description'
          licenses={varyingLicenses.kiosk}
        />
        <TextBlock
          ready={ready}
          title={t('app')}
          name='app_description'
          licenses={varyingLicenses.app}
        />
        <TextBlock
          ready={ready}
          title={t('hotfolder')}
          name='hotfolder_description'
          licenses={varyingLicenses.hotfolder}
        />
        <TextBlock
          ready={ready}
          title={t('userdata')}
          name='userdata_description'
          licenses={varyingLicenses.user_data}
        />
        <TextBlock
          ready={ready}
          title={t('productdata')}
          name='productdata_description'
          licenses={varyingLicenses.productdata}
        />
        <TextBlock
          ready={ready}
          title={t('app_backend')}
          name='app_backend_description'
          licenses={varyingLicenses.app_backend}
        />
        <Form.Item>
          <Space>
            <Button type='primary' htmlType='submit'>
              {t('save')}
            </Button>
          </Space>
        </Form.Item>
      </Form>
    </Wrapper>
  );
};

type TextBlockProps = {
  ready: boolean;
  title: string;
  description?: React.ReactElement;
  name: string;
  licenses?: LicenseType[];
};

function TextBlock(props: TextBlockProps) {
  const { t } = useTranslation();
  const { licenses = [] } = props;

  return (
    <>
      <Title level={3}>{props.title}</Title>
      {props.description && (
        <Form.Item label=' ' colon={false}>
          {props.description}
        </Form.Item>
      )}
      <Form.Item label={t('description')} name={props.name}>
        {props.ready ? <TextEditor /> : <Skeleton />}
      </Form.Item>
      {licenses.length > 0 && (
        <Form.Item label=' ' colon={false}>
          <LicenseList licenses={licenses} />
        </Form.Item>
      )}
    </>
  );
}

function LicenseList(props: { licenses: LicenseType[] }) {
  if (!props.licenses.length) {
    return null;
  }

  return (
    <details>
      <summary>Abweichende Lizenzen: {props.licenses.length}</summary>
      <ul style={{ maxHeight: 250, overflow: 'auto' }}>
        {props.licenses.map((l) => (
          <li key={l.id}>
            <a href={`${routes.licenses_manage}/${l.id}`}>{l.serial}</a>
          </li>
        ))}
      </ul>
    </details>
  );
}

export default TextTemplates;

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;
