import { Alert, Button, Form, Input, Space } from 'antd';
import Title from 'antd/es/typography/Title';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';

import { ModuleType } from '../core/types';
import { useMemo } from 'react';

import { routes } from '../router/routes';
import styled from 'styled-components';
import { addModule, editModule, getSingleModule } from '../core/api/module';
import useFetch from '../core/api/useFetch';

const ModuleEdit = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const { handleResponse, Error } = useFetch();
  const initialValues = {
    name: '',
    description: '',
  };

  const onFinish = async (values: any) => {
    const body: ModuleType = {
      name: values.name,
      description: values.description,
    };
    if (id) {
      body.id = parseInt(id);
      await handleResponse(editModule(id, body), { data: body });
    } else {
      await handleResponse(addModule(body), { data: body });
    }
    navigate(routes.modules, { replace: true });
  };

  useMemo(() => {
    if (id) {
      const fetchModul = async () => {
        const module = await getSingleModule(id);
        const fieldValues = {
          name: module.name,
          description: module.description,
        };
        form.setFieldsValue(fieldValues);
      };
      fetchModul().catch(console.error);
    }
  }, []);

  return (
    <Wrapper>
      <Error />
      <Title level={2}>{id ? t('edit_module') : t('new_module')}</Title>
      <Space style={{ marginBottom: '24px' }}>
        <Alert message={t('save_warning')} type='warning' showIcon />
      </Space>
      <Form
        form={form}
        onFinish={onFinish}
        initialValues={initialValues}
        name='basic'
        labelCol={{ span: 4 }}
        labelWrap
        wrapperCol={{ span: 16 }}
      >
        <Form.Item
          label={t('name')}
          name='name'
          rules={[{ required: true, message: 'Bitte geben Sie dem Modul einen Namen' }]}
        >
          <Input placeholder={t('name') || ''} />
        </Form.Item>
        <Form.Item label={t('description')} name='description'>
          <Input.TextArea />
        </Form.Item>
        <Form.Item>
          <Space>
            <Button type='primary' htmlType='submit'>
              {t('save')}
            </Button>
            <Button htmlType='button' onClick={() => navigate(-1)}>
              {t('cancel')}
            </Button>
          </Space>
        </Form.Item>
      </Form>
    </Wrapper>
  );
};

export default ModuleEdit;

const Wrapper = styled.div`
  width: 100%;
`;
