import { PageType, ModuleType } from '../types';
import put from '../crud/put';
import post from '../crud/post';
import get, { Pagination } from '../crud/get';
import deleteItem from '../crud/delete';

const API_URL = 'api/module';

export const addModule = (body: ModuleType) => {
  return post(API_URL, body);
};

export const editModule = (id: number | string, body: ModuleType) => {
  return put(API_URL, id, body);
};

export const getModules = async (pagination?: Pagination, search?: string) => {
  const searchParameters: any = [];
  if (search) {
    searchParameters.push({ key: 'name', value: search });
    searchParameters.push({ key: 'description', value: search });
  }
  const modules = await get(API_URL, pagination, searchParameters);
  const data = (await modules.json()) as any;
  return data as PageType<ModuleType>;
};

export const getSingleModule = async (id: number | string) => {
  const modules = await get(API_URL, id);
  const data = (await modules.json()) as any;
  return data as ModuleType;
};

export const deleteModule = async (id: number | string) => {
  await deleteItem(API_URL, id);
};
