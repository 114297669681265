import { Typography, Input } from 'antd';
import Table, { ColumnsType, TablePaginationConfig } from 'antd/es/table';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDebounce } from '../hooks';
import styled from 'styled-components';
import { ActivationsType } from '../core/types';
import moment from 'moment';
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import { getDeactivations } from '../core/api/deactivations';
import { Link } from 'react-router-dom';

const { Title } = Typography;

const Activations = () => {
  const { t } = useTranslation();
  const [sort, setSort] = useState('created,desc');
  const [deactivationsData, setDeactivationsData] = useState<ActivationsType[]>([]);
  const [tableSearch, setTableSearch] = useState<string>('');
  const debouncedTableSearch = useDebounce(tableSearch);
  const [pagination, setPagination] = useState<Partial<TablePaginationConfig>>({
    current: 1,
    pageSize: 20,
    showTotal: (total, range) => `${range[0]}-${range[1]} von ${total} Einträgen`,
  });

  useEffect(() => {
    const fetchData = async () => {
      const deactivations = await getDeactivations(
        {
          page: pagination.current! - 1,
          size: pagination.pageSize,
          sort: [sort],
        },
        debouncedTableSearch,
      );
      setDeactivationsData(deactivations.content);
      setPagination({
        ...pagination,
        pageSize: deactivations.size,
        total: deactivations.totalElements,
        showTotal: (total, range) => `${range[0]}-${range[1]} von ${total} Einträgen`,
      });
    };
    fetchData().catch(console.error);
  }, [pagination.current, pagination.pageSize, sort, debouncedTableSearch]);

  const activationsTableColumns: ColumnsType<ActivationsType> = [
    {
      title: t('date'),
      dataIndex: 'created',
      key: 'created',
      defaultSortOrder: 'descend',
      sorter: true,
      render: (text) => moment(text).format('DD.MM.YYYY HH:mm:ss'),
    },
    {
      title: t('status'),
      dataIndex: 'success',
      key: 'success',
      sorter: true,
      render: (_, data) => <>{data.success ? <CheckOutlined /> : <CloseOutlined />}</>,
    },
    {
      title: t('license_data'),
      dataIndex: ['license', 'serial'],
      key: 'license.serial',
      sorter: true,
      render: (serial, data) =>
        data.license ? <Link to={`/licenses/manage/${data.license.id}`}>{serial}</Link> : <></>,
    },
    {
      title: t('release'),
      dataIndex: ['release', 'number'],
      key: 'release.number',
      sorter: true,
      render: (number, data) =>
        data.release ? <Link to={`/releases/manage/${data.release.id}`}>{number}</Link> : <></>,
    },
    {
      title: t('ip_adress'),
      dataIndex: 'remoteIp',
      key: 'remoteIp',
      sorter: true,
    },
    {
      title: t('platform'),
      dataIndex: 'operatingSystem',
      key: 'operatingSystem',
      sorter: true,
    },
  ];

  const handleTableChange = (pagination: TablePaginationConfig, _: any, sorter: any) => {
    setPagination(pagination);
    setSort(`${sorter.columnKey},${sorter.order === 'descend' ? 'desc' : 'asc'}`);
  };

  return (
    <Wrapper>
      <TitleWrapper>
        <Title level={2} style={{ fontWeight: 600 }}>
          {t('deactivations')}
        </Title>
        <TableHeader>
          <Input
            value={tableSearch}
            onChange={(val) => setTableSearch(val.target.value)}
            placeholder={t('search')}
            style={{ width: '270px' }}
          />
        </TableHeader>
      </TitleWrapper>

      <Table
        rowKey={'id'}
        columns={activationsTableColumns}
        dataSource={deactivationsData}
        pagination={{ ...pagination, hideOnSinglePage: true }}
        onChange={handleTableChange}
        expandable={{
          expandedRowRender: (record) => <p style={{ margin: 0 }}>{record.statusText}</p>,
          rowExpandable: (record) => record.statusText !== '',
        }}
      />
    </Wrapper>
  );
};

export default Activations;

const Wrapper = styled.div`
  width: 100%;
`;

const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 1rem;
  margin-bottom: 1rem;
`;

const TableHeader = styled.div`
  display: flex;
  justify-content: end;
  align-items: center;
`;
