import { PageType, ActivationsType } from '../types';
import get, { Pagination } from '../crud/get';

const API_URL = 'api/activation';

export const getActivations = async (
  pagination?: Pagination,
  search?: string,
  staticSearch?: { key: string; value: string }[],
) => {
  const searchParameters: any = [];
  if (search) {
    searchParameters.push({ key: 'release.number', value: search });
    searchParameters.push({ key: 'remoteIp', value: search });
    searchParameters.push({ key: 'created', value: search });
    searchParameters.push({ key: 'operatingSystem', value: search });
    searchParameters.push({ key: 'license.serial', value: search.toUpperCase() });
  }
  if (staticSearch) {
    staticSearch.forEach((element) => {
      const exists = searchParameters.findIndex((e: any) => e.key === element.key);
      if (exists > -1) {
        searchParameters[exists] = element;
      } else {
        searchParameters.push(element);
      }
    });
  }
  const activations = await get(API_URL, pagination, searchParameters);
  const data = (await activations.json()) as any;
  return data as PageType<ActivationsType>;
};

export const getSingleActivation = async (id: number | string) => {
  const activations = await get(API_URL, id);
  const data = (await activations.json()) as any;
  return data as ActivationsType;
};
