import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import styled from 'styled-components';

const modules = {
  toolbar: [
    [{ header: [1, 2, false] }],
    ['bold', 'italic', 'underline', 'strike', 'blockquote'],
    [{ align: [] }, { list: 'ordered' }, { list: 'bullet' }, { indent: '-1' }, { indent: '+1' }],
    ['link', 'code', 'image'],
    ['clean'],
  ],
  clipboard: {
    matchVisual: false,
  },
};

interface OnChangeHandler {
  (e: any): void;
}

type Props = {
  value?: string;
  placeholder?: string;
  noModules?: boolean;
  readOnly?: boolean;
  onChange?: OnChangeHandler;
};

const TextEditor: React.FC<Props> = ({
  value,
  onChange,
  placeholder,
  noModules,
  readOnly,
}: Props) => {
  return (
    <>
      <Editor
        theme='snow'
        value={value || ''}
        modules={noModules ? { toolbar: [] } : modules}
        onChange={onChange}
        placeholder={placeholder}
        readOnly={readOnly}
      />
    </>
  );
};

export default TextEditor;

const Editor = styled(ReactQuill)`
  .ql-toolbar {
    border-radius: 011px 11px 0 0;
  }
  .ql-container {
    border-radius: 0 0 11px 11px;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial,
      'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
      'Noto Color Emoji';
    font-size: 14px;
  }
`;
