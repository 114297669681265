import { Button, Space } from 'antd';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

const Footer = () => {
  const { t } = useTranslation();
  return (
    <Wrapper>
      <Container>
        <Copyright>
          <OffColor>© 2023 </OffColor>ID.on GmbH
        </Copyright>
        <Spacer />
        <Space.Compact direction='horizontal'>
          <Link href='https://www.publishing.one/impressum/'>{t('imprint')}</Link>
          <Link href='https://www.publishing.one/datenschutz/'>{t('data_protection')}</Link>
          <Link href='https://www.publishing.one/'>{t('product')}</Link>
        </Space.Compact>
      </Container>
    </Wrapper>
  );
};

export default Footer;

const Wrapper = styled.div`
  width: 100%;
  height: 49px;
  background-color: #001529;
  display: flex;
  justify-content: center;
`;

const Container = styled.div`
  width: 100%;
  height: 49px;
  max-width: 1240px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

const Spacer = styled.div`
  flex: 1;
`;

const Copyright = styled.span`
  color: white;
`;

const OffColor = styled.span`
  color: grey;
`;

const Link = styled(Button).attrs({ type: 'link', target: '_blank' })`
  color: white;
  font-size: 16px;
`;
